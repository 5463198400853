@charset "utf-8";


/* イベント

   ========================================================================== */


/*doc
---
title: event-available
name: event-available
category: snippets-other
---

```html_example

<div class="_snippets-event-available-box">
    <p class="_text">情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った</p>
</div>

```
*/


._snippets-event-available-box {
  i {
    color: #e75a00;
    font-size: 1.5rem;
    vertical-align: middle;
    white-space: nowrap;
  }

  padding: .6rem 1.5rem;
  border-radius: $border-radius-pc;
  border: 2px solid $border-color;
  background-color: #fafcf1;
  display: inline-block;
  font-weight: bold;
  @include fsize(18px, false, 16px);
  @include mb-small;
  border-color: #a6d100;
  color: $text-color;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  ._text {
    display: inline;
  }

  ._label-box {
    display: inline;
    @include media-sp-max {
      position: absolute;
      top: .6rem;
      right: 1.5rem;
    }
  }
}


