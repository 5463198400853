@charset "utf-8";

/* @  Content Layout
 * ------------------------------------------------------------ */

:root {
  --size: $width-pc-max;
}

@include media-sp-max {
  :root {
    --size: $width-sp-min;
  }
}

body,
html {
  //min-width: var(--size);
  // min-width: $screen-pc-max + px;

  @include media-sp-max {
    //overflow-x: hidden;
    min-width: $width-sp-min;
  }
}


.font-l {

  @include media(min-width ($screen-pc-min / 16) + em) {
    font-size: 24px;
  }
  //
}

.l-main {
  //margin-top: -16px;
  min-height: 20vh;
  background-color: $brand-white;
  margin-bottom: 50px;

  @include media-sp-max {
    margin-top: 16px;
    overflow: hidden;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 46px; //breadcrumb-height
  }
}

.l-main--mb0 {
  margin-top: 0;
  margin-bottom: 0;

  @include media-tab-max {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include media-sp-max {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

}