@charset "utf-8";

/* @  SP nav
 * ------------------------------------------------------------ */

.l-header-cols-sp {
  display: none;
  @include media-tab-max {
    padding: 8px 10px 6px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  ._col-main,
  ._col-sub {
    text-align: center;
    margin: 0 auto;
  }

  //center
  ._col-main {
    flex: 4 4 0;
    a {
      display: block;
    }
  }

  //side
  //icon
  ._col-sub {
    flex: 1 1 0;
    a,
    button {
      display: block;
      text-align: center;
      padding: 0;
      margin: 0 auto;
      white-space: nowrap;
      appearance: none;
      user-select: none;
      outline: none;
      vertical-align: middle;
      background-color: transparent;
      border: 0;

      &:hover {
        text-decoration: none;
      }
    }
    i {
      display: block;
      font-size: 22px;
      color: $text-color-sub;
    }
    span {
      display: block;
      font-size: 10px;
      color: $text-color;
    }
  }

  ._col-order1 {
    order: 1;
  }
  ._col-order2 {
    order: 2;
  }
  ._col-order3 {
    order: 3;
  }
  ._col-order4 {
    order: 4;
  }
  ._col-order5 {
    order: 5;
  }

}

.l-header-search-sp {
  overflow: hidden;
  background-color: #f7f7f7;
  padding: 0 16px;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  height: 0;
  margin-top: -1px;
  box-sizing: border-box;
  transition-delay: .1s;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: $animation-standard-curve-timing-function;
  z-index: 1;

  &[aria-hidden="false"] {
    background: #fff;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 0;
    height: 55px;
    visibility: visible;
    opacity: 1;
  }

  ._body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  ._input {
    width: 100%;
    padding: 6px 15px;
    border: 1px solid $border-color;
    border-radius: $border-radius-pc 0 0 $border-radius-pc;
    box-sizing: border-box;
  }

  ._btn {
    display: block;
    user-select: none;
    font-weight: normal;
    margin: 0 auto;
    //width: 100%;
    width: 56px;
    border-radius: 0 $border-radius-pc $border-radius-pc 0;
    padding: 6px;
    font-size: 18px;
    border: 0;
    color: $text-color;
    background-color: $brand-secondary;
    -webkit-font-smoothing: antialiased;
    appearance: none;
  }

  //google custom検索
  #sp-cse {


    .gsc-search-box {

      .gsc-input-box {
        background: #fff;
        height: auto;
        border: 1px solid $border-color!important;
        border-radius: $border-radius-pc 0 0 $border-radius-pc!important;
        box-sizing: border-box;
        //min-width: 312px;
        box-shadow: 0!important;
      }


      input.gsc-input {
        // _search > _body > _input
        width: 100%;
        padding: 8px 15px!important;
        box-sizing: border-box;


        //google
        min-height: 35px;
        background-position: 15px center!important;
      }

      .gsc-input {
        padding-right: 0!important;
      }

      .gsc-search-button button.gsc-search-button {
        // _search > _body > _btn
        display: block;
        user-select: none;
        font-weight: normal;
        margin: 0 auto;
        //width: 70px;
        width:auto;
        border-radius: 0 $border-radius-pc $border-radius-pc 0;
        //font-size: 26px;
        padding: 6px 18px 6px 18px;
        border: 0;
        color: $text-color;
        background-image:none;
        background-color: $brand-secondary!important;
        -webkit-font-smoothing: antialiased;
        appearance: none;
        //google
        min-height: 40px;
        font-size: 14px;
      }
    }

  }



}

//nav
.l-header-nav-sp {
  //open - start
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(-257px);
  width: 256px;
  background: #fff;
  display: block;
  transform-style: preserve-3d;
  transition-delay: .1s;
  transition-duration: .2s;
  transition-property: transform, left;
  transition-timing-function: $animation-standard-curve-timing-function;
  z-index: 9999;

  &[aria-hidden="false"] {
    transform: translateX(0);
    background: #fff;
  }

  ._body {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 9999;
  }
  //open - end

  nav {
    padding-top: 10px;
  }

  ._list,
  ._list2 {
    border-bottom: 1px solid $border-color;

    i {
      font-size: 18px;
      margin-right: 17px;
      position: relative;
      top: 3px;
    }

    li a {
      line-height: 1.4;
      border-radius: 0;
      font-size: 14px;
      font-weight: bold;
      color: $text-color;
      padding: 16px 22px;
      display: block;

      &:hover {
        text-decoration: none;
      }

      &.is-active {
        background-color: #eef0f4;
      }
    }
  }

  ._list2 {
    li a {
      font-weight: normal;
    }
  }

  ._list2--2column {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    li {
      width: 45%;
    }
  }

}



