@charset "utf-8";

/* ==========================================================================
   #media
   ========================================================================== */

/*doc
---
title:
name: media1
category: media
---

<h3 class="styleguide">Default</h3>

```html_example

<div class="c-media  c-media--large c-media--break">
  <div class="c-media__img"><img src="/assets/img/dummy/192x128.png" alt=""></div>
  <div class="c-media__body">
    <p class="c-media__title">情に棹させば流される智に働けば角が立つ</p>
    <p class="c-media__text">情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。</p>
  </div>
</div>

```


*/

.c-media {
  @include mb-small;

  > a {
    width:100%;
    display: block;
    overflow: hidden;
    color: inherit;
  }

  @include clearfix;
  color: inherit;
  display: block;

  &__img {

    + .c-media__img {
      margin-bottom: 10px;
    }

    overflow: hidden;
    position: relative;
    margin-right: 24px;
    float: left;
    width: 192px;
    height: 128px;

    @include media-sp-max {
      margin-right: 10px;
      width: 104px;
      height: 69px;
    }

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }

  &__body {
    overflow: hidden;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    margin-bottom: .2em;
    width: 100%;
    display: inline-block;
  }
  &__title {
    @include fsize($fsize-h4-pc, false, $fsize-h4-sp);
    margin-bottom: .3em;
    line-height: 1.4;
  }

  &__text {
    color: $text-color-sub;
    @include fsize(14px);

    + .c-media__text {
      margin-top: $margin-small-pc;

      @include media-sp-max {
        margin-top: $margin-small-sp;
      }
    }
  }

}

.c-media--small {

  .c-media__title {
    @include fsize(14px);
  }

  .c-media__text {
    @include fsize(12px);
  }

  .c-media__img {
    margin-right: 16px;
    float: left;
    width: 135px;
    height: 90px;

    @include media-sp-max {
      margin-right: 10px;
      width: 104px;
      height: 69px;
    }
  }

}