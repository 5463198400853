@charset "utf-8";

/* @  main visual
 * ------------------------------------------------------------ */
/*doc
---
title:
name: main-visual
category: main-visual
---


<h3 class="styleguide">Main Visual Full</h3>
<br>

```html_example
  <div class="c-main-visual c-main-visual--full">
    <div class="c-main-visual__inner">
      <img src="/assets/img/main_visual.png" alt="">
    </div>
  </div>

```

<h3 class="styleguide">Main Visual Default</h3>
<br>

```html_example
  <div class="c-main-visual is-gapless--sm">
    <div class="c-main-visual__inner">
      <a href=""><img src="/assets/img/main_visual.png" alt=""></a>
    </div>
  </div>
```

*/



.c-main-visual {
  margin-top: -24px;
  text-align: center;
  @include mb-small;

  a {
    display: block;
  }

  &__title {
    margin-bottom: 0;
  }

  &--full {
    overflow: hidden;
    max-height: 30rem;
    img {
      width: 100%;
    }
  }

}


