@charset "utf-8";

/* @  Footer Layout
 * ------------------------------------------------------------ */

/*doc
---
title: footer
name: footer
category: footer
---

```html_example
  <footer class="l-footer">
  </footer>
```
*/

.wrapper {
  position: relative;
}

.l-footer-img {
  position: relative;
  margin-bottom: -5px;
  img {
    width:100%;
  }


  + .l-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(41, 39, 94, .84);
    @include media-tab-max {
      position: relative;
      background-color: #29275e;
    }

  }
}

.l-footer {

  background-color: #29275e;
  color: #fff;
  margin-top: 5px;

  box-shadow: 0 -2px 5px rgba(0, 0, 0, .26);

  ._inner {
    max-width: $width-pc-max;
    margin-left: auto;
    margin-right: auto;
    padding-right: $width-gap-pc;
    padding-left: $width-gap-pc;

    @include media-tab-max {
      padding-right: $width-gap-tab;
      padding-left: $width-gap-tab;
    }

    @include media-sp-max {
      padding-right: $width-gap-sp;
      padding-left: $width-gap-sp;
    }
  }

  ._body {
    padding-top: 14px;
    @include clearfix;

    @include media-sp-max {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
    }
  }

  ._item {
    padding-bottom: 17px;
    float: left;
    width: 50%;

    @include media-tab-max {
      width: 55%;
    }

    @include media-sp-max {
      float: none;
      width: auto;
      clear: bottom;
    }

  }

  ._item:first-child {
    width: 30%;
    @include media-tab-max {
      width: 45%;
    }
    @include media-sp-max {
      width: auto;
      order: 3;
    }
  }

  ._item:last-child {
    float: right;
    width: 20%;
    @include media-tab-max {
      width: auto;
      float: none;
      clear:both;
    }
    @include media-sp-max {
      order: 1;
    }

  }

  ._description {
    margin-right: 10px;
  }

  ._title {
    font-size: 20px;
    @include media-sp-max {
      font-size: 16px;
    }
  }

  ._address {
    font-size: 14px;
    span {
      display: block;
    }
  }

  ._tel {
    font-size: 14px;
  }

  ._link {

    li {
      width: 30%;
      font-size: 13px;
      display: inline-block;

      @include media-tab-max {
        width: 49%;
      }

      @include media-sp-max {
        margin-bottom: 1em;
      }

    }
    a {
      color: inherit;
    }

  }

  ._icon {

    display: flex;
    align-items: center;
    gap: 10px;
    justify-content:flex-end;
    @include media-tab-max {
    }

    @include media-sp-max {
      text-align: center;
      margin-bottom: 1em;
      justify-content:center;

    }

    li {
      display: inline-block;
      margin-bottom: 5px;
      //margin-right: 10px;
      //&:last-child {
      //  margin-right: 0;
      //}
    }

    a {
      background-color: rgba(255, 255, 255, .3);
      padding: 7px 6px 7px 8px;
      text-align: center;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      color: inherit;
      font-size: 24px;
      line-height: 1;
      display: block;
    }
  }

  ._copy {
    text-align: center;

    color: $text-color-dark-week;
    border-top: 1px solid $text-color-dark-week;
  }

  ._copy-text {
    max-width: $width-pc-max;
    padding: .5em $width-gap-pc .8em;
    margin-right: auto;
    margin-left: auto;
    font-size: 10px;
    text-align: center;
  }

}