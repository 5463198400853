@charset "utf-8";

/* @ l-content-block
 * ------------------------------------------------------------ */


.l-content-block {
  // @include mb-small;

  width: ($screen-main-wide-colum / $screen-pc-max) * 100%;
  max-width: $screen-main-wide-colum + px;
  margin-left: auto;
  margin-right: auto;

  @include media-tab-max {
    width: 100%;
  }

  @include media-sp-max {
    width: 100%;
  }
}

.l-content-block-full {
  width: 100%;
}

/* @ l-primary-block, l-secondary-block
 * ------------------------------------------------------------ */
.l-primary-block {
  @include mb-small;

  width: ($screen-main-colum / $screen-pc-max) * 100%;
  float: right;
  @include media-sp-max {
    float: none;
    width: 100%;
  }

}

.l-secondary-block {
  @include mb-small;

  width: ($screen-sub-colum / $screen-pc-max) * 100%;
  float: left;

  @include media-sp-max {
    float: none;
    width: 100%;
  }
}

.l-widget {
  margin-bottom: $margin-large-pc;
  *:last-child {
    margin-bottom: 0;
  }

  @include media-sp-max {
    margin-bottom: 0;
    border-bottom: 1px solid $border-color;
    margin-right: -$width-gap-sp;
    margin-left: -$width-gap-sp;
  }

}


