@charset "utf-8";

/* ==========================================================================
   #pagination
   ========================================================================== */

/*doc
---
title:
name: pagenavi
category: pagination
---

```html_example
<div class="c-pagenavi">
  <a class="c-previouspostslink" rel="prev" href="javascript:void(0)"></a>
  <span class="is-current">1</span>
  <a href="javascript:void(0)">2</a>
  <a href="javascript:void(0)">3</a>
  <a href="javascript:void(0)">4</a>
  <a href="javascript:void(0)">5</a>
  <span class="extend">...</span>
  <a class="c-nextpostslink" rel="next" href="javascript:void(0)"></a>
</div>


```
*/

.c-pagenavi {
  text-align: center;
  @include mb-small;

  .cur,
  .is-current,
  .c-extend,
  a {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10px;
    display: inline-block;
    min-width: 36px;
    height: 36px;
    transition: all $animation;
    background-color: $brand-white;
    line-height: 34px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    border: 1px solid $border-color;
    font-size: .75em;
    color: $text-color-sub;
  }

  .cur,
  .is-current,
  a:hover {
    border: 1px solid $border-color;
    background-color: #5451aa;
    color: $brand-white;
    &:before {
      border-color: #fff;
    }
  }
}

.c-previouspostslink,
.c-nextpostslink {
  position: relative;

  &:before {
    display: inline-block;
    position: relative;
    top: -.1em;
    bottom: 0;
    right: 0;
    left: 0;
    width: 4px;
    height: 4px;
    margin: auto;
    transform: rotate(45deg);
    border: 1px solid $text-color-sub;
    content: "";
    vertical-align: middle;
  }

}

.c-previouspostslink:before {
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  border-left-width: 1px;
  left: .1em;
}

.c-nextpostslink:before {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 0;
  border-left-width: 0;
  right: .1em;
}

/*doc
---
title:
name: pager
category: pagination
---

```html_example

  <div class="c-pager">
    <ul class="c-pager__inner">
      <li class="c-pager__prev"><a href="javascript:void(0)" class="e-btn is-disabled">前の記事を読む</a></li>
      <li class="c-pager__next"><a href="javascript:void(0)" class="e-btn">次の記事を読む</a></li>
    </ul>
  </div>


```
*/

.c-pager {
  margin-bottom: $margin-small-pc;

  a {
    text-decoration: none;
  }

  @include media-sp-max {
    margin-bottom: $margin-small-sp;
  }

  &__inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    position: relative;

    @include media-sp-max {
      //justify-content: center;
    }
  }

  &__prev {
    margin-bottom: 20px;
    margin-right: 10px;
    // left: 0;
    // position: absolute;

    @include media-sp-max {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__next {
    margin-bottom: 20px;
    margin-left: 10px;
    //right: 0;
    //position: absolute;

    @include media-sp-max {
      margin-left: 0;
      margin-right: 0;
    }
  }
}