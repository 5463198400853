@charset "utf-8";

/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/* ボタンベース
   ========================================================================== */

/*doc
---
title: Color
name: btn
category: btn
---


<h3 class="styleguide">Default</h3>

```html_example

<div class="e-btn-group is-left">
  <button id="btn1" class="e-btn e-btn--primary" type="button"><i class="material-icons is-left">navigate_before</i>もっと見る<i class="material-icons is-right">navigate_next</i></button>
  <button class="e-btn e-btn--primary is-hover" type="button">もっと見る
  </button>
  <button class="e-btn e-btn--primary is-disabled" type="button">もっと見る</button>
</div>

```


<h3 class="styleguide">Secondary</h3>

```html_example

<div class="e-btn-group is-left">
  <button class="e-btn e-btn--secondary" type="button"><i class="material-icons is-left">navigate_before</i>もっと見る<i class="material-icons is-right">navigate_next</i></button>
  <button class="e-btn e-btn--secondary is-hover" type="button">もっと見る</button>
  <button class="e-btn e-btn--secondary is-disabled" type="button">もっと見る</button>
</div>

```

```
*/

// .rich-editor 内でもつかるように btn のcolor とtext-decoration は 「!important」とします

$btn-padding-vertical: .9rem;
$btn-padding-horizon: 1.6rem;
$btn-line-height: 1.2;
$btn-border: 0;
$btn-border-radius: 2px;

.e-btn {

  font-weight: bold;
  text-decoration: none !important;
  color: $text-color !important;
  padding: $btn-padding-vertical $btn-padding-horizon;
  min-width: 64px;
  max-width: 100%;
  box-sizing: border-box;
  transition: all $animation;
  border-radius: $btn-border-radius;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  white-space: nowrap;
  appearance: none;
  user-select: none;
  outline: none;
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: $btn-line-height;
  background-color: #fff;
  border: $btn-border;

  @include media-sp-max {
    padding: ($btn-padding-vertical) ($btn-padding-horizon * .8);
  }

  @include elevation(2);
  @include elevation-transition;

  &:hover,
  &:focus,
  &.is-hover {
    // background-color: shade($brand-primary, 10%);
    text-decoration: none;
    @include elevation(4);
  }

  &:active {
    //  background-color: shade($brand-primary, 10%);
    text-decoration: none;
    @include elevation(8);
  }

  &:disabled,
  &.is-disabled {
    cursor: not-allowed;
    background-color: #eee;
    border: 1px solid $border-color;
    color: #666 !important;
    @include elevation(0);
  }

  .is-left,
  .is-right {
    position: absolute;
    top: 0;
    margin: auto;
    bottom: 0;
    height: 1em;
    width: 1em;
  }

  .is-left {
    left: .5em;
  }

  .is-right {
    right: .5em;
  }

}

//.e-btn--primary {  }

.e-btn--secondary {

  background-color: #fff;
  color: $brand-primary !important;
  border: 1px solid $brand-primary;

  &:hover,
  &:focus,
  &:active,
  &.is-hover {
    color: #fff !important;
    background-color: shade($brand-primary, 0);
  }

  &:disabled,
  &.is-disabled {
    box-shadow: none;
    background-color: #eee;
    color: #666 !important;

  }

}

/* ボタングループ
   ========================================================================== */
/*doc
---
title: Group
name: btn-group
category: btn
---

```html_example
<div class="e-btn-group is-left">
  <button type="button" class="e-btn">もっと見る</button>
  <button type="button" class="e-btn">もっと見る</button>
</div>

<div class="e-btn-group">
  <button type="button" class="e-btn">もっと見る</button>
  <button type="button" class="e-btn">もっと見る</button>
</div>


```

*/

.e-btn-group {
  margin-right: -$screen-gap-pc + px;
  margin-left: -$screen-gap-pc + px;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  @include mb-small;

  .e-btn {
    margin-bottom: $margin-xs-pc;
    margin-right: $screen-gap-pc + px;
    margin-left: $screen-gap-pc + px;

    @include media-sp-max {
      width: 100%;
    }
  }
}

.e-btn-group--one-half {
  .e-btn {
    width: 50%;
  }
}

/* ボタンサイズ
   ========================================================================== */

/*doc
---
title: Size
name: btn-size
category: btn
---

```html_example
<div class="e-btn-group e-btn-group--left">
  <button type="button" class="e-btn e-btn--small">もっと見る</button>
  <button type="button" class="e-btn e-btn--medium">もっと見る</button>
  <button type="button" class="e-btn e-btn--large">もっと見る</button>
  <button type="button" class="e-btn e-btn--xlarge">もっと見る</button>
</div>

```

*/

.e-btn--rss {
  @include media-sp-max {
    width: auto !important;
  }

  &:before {
    @include material-icons;
    vertical-align: middle;
    content: "rss_feed";
    @include fsize(24px);
    color: #f90;
    position: relative;
    top: -3px;
    bottom: 0;
    margin-right: 6px;
    margin-bottom: -2px;
  }
}

.e-btn--small,
.e-btn--rss {
  padding: ($btn-padding-vertical * .4) ($btn-padding-horizon * .55);
  font-size: .75em;
  font-weight: normal;

  @include media-sp-max {
    padding: ($btn-padding-vertical * .4) ($btn-padding-horizon * .4);
  }

}

.e-btn--medium {
  //
}

.e-btn--large {
  padding: ($btn-padding-vertical * 1.4) ($btn-padding-horizon);
  font-size: 1.2em;
  font-weight: bold;

  @include media-sp-max {
    padding: ($btn-padding-vertical * 1.4) ($btn-padding-horizon);
  }

}

.e-btn--xlarge {
  padding: ($btn-padding-vertical * 1.5) ($btn-padding-horizon);
  font-size: 1.4em;
  font-weight: bold;
  min-width: 30%;

  @include media-sp-max {
    padding: ($btn-padding-vertical * 1.4) ($btn-padding-horizon);
  }

}

