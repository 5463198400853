@charset "utf-8";

/*doc
---
title: richeditor Sample
name: richeditor
category: richeditor
---
<p class="e-text is-mb-medium">
ニュースの詳細などCMSで直接タグ入力するして更新する場合は外枠のdivにを指定してください。
こちらのサンプルのような形になり内包するタグ（p,span,table,list,dlなど）にベース装飾を追加します。
このため内ではサンプル以外のクラス.mediaなどは使用出来ませんのでご注意ください。
</p>

```html_example
        <div class="rich-editor">

          <ol>
            <li>見出し・段落</li>
            <li>リスト</li>
            <li>テキスト（装飾・リンク・配置・引用）</li>
            <li>画像（サイズ・配置）</li>
            <li>ボックス</li>
            <li>テーブル</li>
            <li>その他パーツ</li>
          </ol>

          <h2>1. 見出し・段落</h2>
          <h2>見出し2</h2>
          <h2><a href="">見出し2（リンク有り）</a></h2>
          <h3>見出し3</h3>
          <h4>見出し4</h4>
          <h5>見出し5</h5>
          <h6>見出し6</h6>

          <p>これは標準の段落です。本文はここに入力してください。<br>「Enter」キーを押すと段落が変わります。</p>
          <p>「Shift」 + 「Enter」キーを押すと段落は変わらずに改行されます。</p>

          <h2>2. リスト</h2>

          <ul>
            <li>順序がないリスト順序がないリスト順序がないリスト順序がないリスト順序がないリスト順序がないリスト順序がないリスト順序がないリスト順序がないリスト順序がないリスト順序がないリスト</li>
            <li>順序がないリスト
              <ul>
                <li>順序がないリスト</li>
                <li>順序がないリスト</li>
              </ul>
            </li>
            <li>順序がないリスト</li>
          </ul>

          <ol>
            <li>順序のあるリスト順序のあるリスト順序のあるリスト順序のあるリスト順序のあるリスト順序のあるリスト順序のあるリスト順序のあるリスト順序のあるリスト順序のあるリスト順序のあるリスト</li>
            <li>順序のあるリスト
              <ol>
                <li>順序のあるリスト</li>
                <li>順序のあるリスト</li>
              </ol>
            </li>
            <li>順序のあるリスト</li>
          </ol>

          <ul class="e-list--arrow">
            <li><a href="#dummy">矢印付きリスト（リンクと併用）矢印付きリスト（リンクと併用）矢印付きリスト（リンクと併用）矢印付きリスト（リンクと併用）矢印付きリスト（リンクと併用）矢印付きリスト（リンクと併用）矢印付きリスト（リンクと併用）矢印付きリスト（リンクと併用）矢印付きリスト（リンクと併用）矢印付きリスト（リンクと併用）矢印付きリスト（リンクと併用）</a></li>
            <li><a href="#dummy">矢印付きリスト（リンクと併用）</a></li>
            <li><a href="#dummy">矢印付きリスト（リンクと併用）</a></li>
          </ul>

          <ul>
            <li class="arrow">矢印付きリスト（リンクとなし）矢印付きリスト（リンクとなし）矢印付きリスト（リンクとなし）矢印付きリスト（リンクとなし）矢印付きリスト（リンクとなし）矢印付きリスト（リンクとなし）矢印付きリスト（リンクとなし）矢印付きリスト（リンクとなし）矢印付きリスト（リンクとなし）矢印付きリスト（リンクとなし）</li>
            <li class="arrow">矢印付きリスト（リンクとなし）</li>
            <li class="arrow">矢印付きリスト（リンクとなし）</li>
          </ul>

          <ul>
            <li class="point">ポイントリストポイントリストポイントリストポイントリストポイントリストポイントリストポイントリストポイントリストポイントリストポイントリストポイントリストポイントリスト</li>
            <li class="point">ポイントリスト</li>
            <li class="point">ポイントリスト</li>
          </ul>

            <h3 id="list-style-type">箇条書き</h3>
            <ul>
              <li>デフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルト</li>
              <li>デフォルト</li>
            </ul>
            <ul class="is-list-style-type--circle">
              <li>円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円円</li>
              <li>円</li>
            </ul>
            <ul class="is-list-style-type--disc">
              <li>点</li>
              <li>点</li>
            </ul>
            <ul class="is-list-style-type--square">
              <li>四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角四角</li>
              <li>四角</li>
            </ul>

            <h3>番号付き箇条書き</h3>
            <ol>
              <li>デフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルトデフォルト</li>
              <li>デフォルト</li>
            </ol>

            <ol class="is-list-style-type--lower-alpha">
              <li>小文字のアルファベット表示小文字のアルファベット表示小文字のアルファベット表示小文字のアルファベット表示小文字のアルファベット表示小文字のアルファベット表示小文字のアルファベット表示小文字のアルファベット表示小文字のアルファベット表示</li>
              <li>小文字のアルファベット表示</li>
            </ol>
            <ol class="is-list-style-type--lower-greek">
              <li>小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字小文字のギリシャ文字</li>
              <li>小文字のギリシャ文字</li>
            </ol>
            <ol class="is-list-style-type--lower-roman">
              <li>小文字のローマ数字小文字のローマ数字小文字のローマ数字小文字のローマ数字小文字のローマ数字小文字のローマ数字小文字のローマ数字小文字のローマ数字小文字のローマ数字小文字のローマ数字小文字のローマ数字</li>
              <li>小文字のローマ数字</li>
            </ol>
            <ol class="is-list-style-type--upper-alpha">
              <li>大文字のアルファベット大文字のアルファベット大文字のアルファベット大文字のアルファベット大文字のアルファベット大文字のアルファベット大文字のアルファベット大文字のアルファベット大文字のアルファベット大文字のアルファベット大文字のアルファベット</li>
              <li>大文字のアルファベット</li>
            </ol>
            <ol class="is-list-style-type--upper-roman">
              <li>大文字のロマ数字大文字のロマ数字大文字のロマ数字大文字のロマ数字大文字のロマ数字大文字のロマ数字大文字のロマ数字大文字のロマ数字大文字のロマ数字大文字のロマ数字大文字のロマ数字</li>
              <li>大文字のロマ数字</li>
            </ol>



          <dl>
            <dt>定義リスト</dt>
            <dd>テキストが入ります。テキストが入ります。</dd>
            <dt>定義リスト</dt>
            <dd>テキストが入ります。テキストが入ります。</dd>
            <dt>定義リスト</dt>
            <dd>テキストが入ります。テキストが入ります。</dd>
          </dl>

          <h2>3. テキスト（装飾・リンク・配置・引用）</h2>
          <h3>装飾</h3>
          <p>
            <strong>強い強調を表す要素（strong）</strong><br>
            <em>強調を表す要素（em）</em><br>
            <s>すでに正確ではなくなった要素、打ち消し線（s）</s><br>
            <small>注釈や細目を表す要素（small）</small><br>
            <span>上付き文字（sup）　E=mc<sup>2</sup></span><br>
            <span>下付き文字（sub）　CO<sub>2</sub></span><br>
            <span class="is-color-orange">重要色</span><br>
            <span class="is-color-red">エラー色</span>
          </p>

          <h3>リンク</h3>
          <p>
            <a href="#dummy">標準のリンク</a><br>
            <a href="#dummy" target="_blank">target="_blank"を指定している場合 </a><br>
            <a href="#dummy.pdf">pdfへのリンクの場合</a><br>
            <a href="#dummy.doc">doc、docxへのリンクの場合</a><br>
            <a href="#dummy.xls">xls、xlsxへのリンクの場合</a><br>
            <a href="#dummy.ppt">ppt、pptxへのリンクの場合</a><br>
            <a href="#dummy.ppt" class="noicon">.noicon自動リンクアイコンを非表示</a>
          </p>


          <p><span class="noicon"><a href="#dummy.ppt">.noicon自動リンクアイコンを非表示2</a></span></p>

          <h3>配置</h3>
          <p class="is-left">左揃え<br>
            吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。吾輩はここで始めて人間というものを見た。しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。</p>
          <p class="is-center">
            中央揃え<br>
            吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。吾輩はここで始めて人間というものを見た。しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。</p>
          <p class="is-right">
            右揃え<br>
            吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。吾輩はここで始めて人間というものを見た。しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。</p>
          <p class="is-justify">
            両端揃え<br>
            吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。吾輩はここで始めて人間というものを見た。しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。</p>

          <h3>引用</h3>
          <blockquote>
            引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。引用テキストが入ります。
          </blockquote>

          <h2>4. 画像（サイズ・配置）</h2>

          <h3>サイズ</h3>
          <figure>
            <img src="/assets/img/dummy/192x128.png" alt="">
          </figure>
          <figure class="is-one-half">
            <img src="/assets/img/dummy/192x128.png" alt="">
          </figure>
          <figure class="is-one-third">
            <img src="/assets/img/dummy/192x128.png" alt="">
          </figure>
          <figure class="is-one-fourth">
            <img src="/assets/img/dummy/192x128.png" alt="">
          </figure>


          <h3>配置</h3>
          <h4>左寄せ（テキスト回り込み）</h4>
          <figure class="is-left is-one-fourth">
            <img src="/assets/img/dummy/192x128.png" alt="">
          </figure>
          <p>画像を選択した状態でプルダウンメニュー「スタイル」から「Float Left」を選択すると、画像が左側に寄り、テキストが回り込みます。回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込みを解除するには 「div.clearfloat」（スタイルでは「Clear Float」）を挿入します。</p>

          <h4>中央寄せ</h4>
          <figure class="is-center is-one-fourth">
            <img src="/assets/img/dummy/192x128.png" alt="">
          </figure>

          <h4>右寄せ（テキスト回り込み）</h4>
          <figure class="is-right is-one-fourth">
            <img src="/assets/img/dummy/192x128.png" alt="">
          </figure>
          <p>画像を選択した状態でプルダウンメニュー「スタイル」から「Float Right」を選択すると、画像が右側に寄り、テキストが回り込みます。回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込み回り込みを解除するには「div.clearfloat」（スタイルでは「Clear Float」）を挿入します。</p>

          <h2>5. ボックス</h2>
          <div class="e-box-bordered">
            <div class="e-box-bordered__body">
              <p class="e-box-bordered__title">お問い合わせ</p>
              <p><a href="">経営企画本部</a>　 096-342-2031</p>
            </div>
          </div>

          <div class="e-box e-box--error">
            <div class="e-box__body">
              <p>ボックス（重要）<br>このように、選択した部分に背景色と枠線のスタイルがつきます。</p>
            </div>
          </div>

          <div class="e-box e-box--warning">
            <div class="e-box__body">
              <p>ボックス（補足）<br>このように、選択した部分に背景色と枠線のスタイルがつきます。</p>
            </div>
          </div>

          <div class="e-box e-box--bg">
            <div class="e-box__body">
              <p>ボックス（メモ）<br>このように、選択した部分に背景色と枠線のスタイルがつきます。</p>
            </div>
          </div>

          <h2>6. テーブル</h2>

          <h3>標準</h3>
          <table>
            <tr>
              <th>見出し</th>
              <th>見出し</th>
              <th>見出し</th>
            </tr>
            <tr>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
            </tr>
            <tr>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
            </tr>
          </table>

          <table>
            <tr>
              <th>見出し</th>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
            </tr>
            <tr>
              <th>見出し</th>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
            </tr>
            <tr>
              <th>見出し</th>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
            </tr>
          </table>


        <h3>ボーダ無し</h3>

          <table class="noborder">
            <tr>
              <th>見出し</th>
              <th>見出し</th>
              <th>見出し</th>
            </tr>
            <tr>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
            </tr>
            <tr>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
            </tr>
          </table>

          <table class="noborder">
            <tr>
              <th>見出し</th>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
            </tr>
            <tr>
              <th>見出し</th>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
            </tr>
            <tr>
              <th>見出し</th>
              <td>テキストが入ります。</td>
              <td>テキストが入ります。</td>
            </tr>
          </table>

          <h3>SP時スクロール</h3>
          <div class="e-responsive-table">
            <table>
              <tr>
                <th>見出し</th>
                <th>見出し</th>
                <th>見出し</th>
              </tr>
              <tr>
                <td>テキストが入ります。</td>
                <td>テキストが入ります。</td>
                <td>テキストが入ります。</td>
              </tr>
              <tr>
                <td>テキストが入ります。</td>
                <td>テキストが入ります。</td>
                <td>テキストが入ります。</td>
              </tr>
            </table>
          </div>

          <h2>7. その他パーツ</h2>
          <h3>Adobe Acrobat Reader</h3>
          <div class="_snippets-acrobat-reader">
            <p class="_text">PDFファイルをご覧いただくためには、Adobe Acrobat Readerが必要です。アドビ社のサイトより無料でダウンロード可能です。</p>
            <div class="_img"><a href="#dummy"><img src="/assets/img/common/get_adobe_acrobat_reader.png" alt=""></a> </div>
          </div>

        </div>

```
*/

.rich-editor {

  img {
    height: auto;
  }

  //要素の間隔
  p,
  table,
  ul,
  ol,
  dl,
  blockquote,
  figure,
  .e-box-bordered,
  hr {
    @include mb-medium;
  }

  
  p + p {
    margin-top: - $margin-medium-pc / 2;

    @include media-tab-max {
      margin-top: - $margin-medium-tab / 2;
    }

    @include media-sp-max {
      margin-top: - $margin-medium-sp / 2;
    }
  }


  //空タグは無視する
  p:empty {
    display: none;
  }

  
  s {
    opacity: .54;
  }

  em {
    font-weight: bold;
    font-style: normal;
  }


  //旧ソース対応追加

  .clearfloat,
  .visualClear {
    clear: both;
  }

  .point {
    margin-left: 0;
    list-style-type: none;
  }

  .arrow {
    list-style-type: none;
    text-indent: -.6em;
    padding-left: .8em;
    &:before {
      color: #757575;
      @include material-icons;
      vertical-align: middle;
      content: "navigate_next";
      white-space: nowrap;
      font-size: 1.2em;
    }
  }

  .point {
    list-style-type: none;
    text-indent: -.8em;
    padding-left: .8em;
    &:before {
      background-color: $brand-primary-light;
      width: .5em;
      height: .5em;
      display: inline-block;
      vertical-align: middle;
      margin-right: .3em;
      margin-top: -.1em;
      content: '';
    }
  }

  .indent {
    text-indent: 1em;
  }

  .doublecolumn {
    float: left;
    box-sizing: border-box;
    width: 48%;
    margin-right: 1%;
    margin-left: 1%;
    @include media-sp-max {
      float: none;
      margin-right: 0;
      margin-left: 0;
    }
  }

  em {
    font-style: italic;
  }

  .marker {
    background: $brand-yellow;
    padding: .2em;
  }

  .fontL {
    font-size: 1.2em;
  }

  .fontS {
    font-size: .8em;
  }

  .underline {
    text-decoration: underline;
  }

  .fontcolor1 {
    color: $brand-red;
  }
  .fontcolor2 {
    color: $brand-blue;
  }
  .fontcolor3 {
    color: tint($brand-green, 40%);
  }

  .bgcolor1 {
    background-color: tint($bg-color, 60%);
  }
  .bgcolor2 {
    background-color: $brand-yellow;
  }
  .bgcolor3 {
    background-color: $brand-red;
  }
  .bgcolor4 {
    background-color: tint($brand-green, 40%);
  }
  .bgcolor5 {
    background-color: $bg-color;
  }
  .subtotal {
    background-color: tint($brand-gray, 60%);
  }
  .total {
    background-color: tint($brand-gray, 30%);
  }
  .tbgcolor1 {
    background-color: $brand-primary-light;
    color: #fff;
  }
  .tbgcolor2 {
    background-color: $brand-secondary-light;

  }


  .eventimg {
    margin-left: 1rem;
    float: right;
    margin-top: .3em;
    max-width: 25%;
    @include mb-medium;

    p {
      text-align: center;
      margin-top: .5em;
      color: $text-color-sub;
      font-size: .9rem;
      line-height: 1.5;
    }
    @include media-sp-max {
      max-width: inherit;
      width:auto;
      float: none;
    }
  }

}



