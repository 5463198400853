@charset "utf-8";

/* ==========================================================================
   #12 Column Grid System
   ========================================================================== */

/*doc
---
title: 12 Column Grid System
name: grid-12column1
category: grid-12column
---

```html_example


<div class="is-cols">
  <div class="is-col12 dummy-box">1/1<br>12 column</div>
</div>
<div class="is-cols">
  <div class="is-col6 is-offset3 dummy-box">1/2<br>6 column, offset3 </div>
</div>

<div class="is-cols is-cols--break">
  <div class="is-col3 dummy-box">1/2<br>3 column</div>
  <div class="is-col3 is-offset6 dummy-box">1/2<br>3 column, offset6 </div>
</div>

<div class="is-cols">
  <div class="is-col6 dummy-box">1/2<br>6 column</div>
  <div class="is-col6 dummy-box">1/2<br>6 column</div>
</div>
<div class="is-cols is-cols--break">
  <div class="is-col4 dummy-box">1/3<br>4 column</div>
  <div class="is-col4 dummy-box">1/3<br>4 column</div>
  <div class="is-col4 dummy-box">1/3<br>4 column</div>
</div>
<div class="is-cols">
  <div class="is-col3 dummy-box">1/4<br>3 column</div>
  <div class="is-col3 dummy-box">1/4<br>3 column</div>
  <div class="is-col3 dummy-box">1/4<br>3 column</div>
  <div class="is-col3 dummy-box">1/4<br>3 column</div>
</div>
<div class="is-cols">
  <div class="is-col2 dummy-box">1/6<br>2 column</div>
  <div class="is-col2 dummy-box">1/6<br>2 column</div>
  <div class="is-col2 dummy-box">1/6<br>2 column</div>
  <div class="is-col2 dummy-box">1/6<br>2 column</div>
  <div class="is-col2 dummy-box">1/6<br>2 column</div>
  <div class="is-col2 dummy-box">1/6<br>2 column</div>
</div>
<div class="is-cols">
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
  <div class="is-col1 dummy-box">1/12<br>1 column</div>
</div>
```

*/

/*doc
---
title: Inside - Column
name: grid-12column2-inner
category: grid-12column
---

```html_example


<div class="is-cols is-cols--break">
  <div class="is-col9 dummy-box">3/4<br>9 column
    <div class="is-cols is-cols--inside">
      <div class="is-col6 is-offset3 dummy-box">6 columns(inside), offset3(inside)</div>
     </div>
     <div class="is-cols is-cols--inside is-cols--break">
      <div class="is-col6 dummy-box">6 columns(inside)</div>
      <div class="is-col6 dummy-box">6 columns(inside)</div>
     </div>
      <div class="is-cols is-cols--inside">
      <div class="is-col4 dummy-box">1/3(inside)<br>4 column</div>
      <div class="is-col4 dummy-box">1/3(inside)<br>4 column</div>
      <div class="is-col4 dummy-box">1/3(inside)<br>4 column</div>
     </div>
  </div>
  <div class="is-col3 dummy-box">1/4<br>3 column</div>
</div>
```
*/

/*doc
---
title: Gspless - Column
name: grid-12column3-gapless
category: grid-12column
---


```html_example

<div class="is-cols is-cols--gapless is-cols--break js-col-assy-match-height">
  <div class="is-col8 dummy-box">2/3<br>8 column  </div>
  <div class="is-col4 dummy-box">1/3<br>4 column
   <div class="is-cols is-cols--gapless">
     <div class="is-col12 dummy-box">12 column  </div>
     <div class="is-col12 dummy-box">12 column  </div>
   </div>
  </div>
</div>

<div class="is-cols is-cols--gapless ">
  <div class="is-col6 is-offset3 dummy-box">1/2<br>6 column, offset3 </div>
</div>

<div class="is-cols  is-cols--gapless  is-cols--break">
  <div class="is-col3 dummy-box">1/2<br>3 column</div>
  <div class="is-col3 is-offset6 dummy-box">1/2<br>3 column, offset6 </div>
</div>

```

*/

$grid: 12;
$grid-gap: 12;
$outside-width: $screen-pc-max;
$inside-width: $screen-main-colum;
$outside-gap: $grid-gap;
$inside-gap: $grid-gap * 1.2;
$outside-bottom: $outside-gap * .16%;
$inside-bottom: $inside-gap * .26%;

$outside-columns-auto: ($outside-width / $grid) - (2 * $outside-gap);
$outside-grid-auto: ($outside-gap * ($grid * 2)) + ($outside-columns-auto * $grid);

$inside-columns-auto: ($inside-width / $grid) - (2 * $inside-gap);
$inside-grid-auto: ($inside-gap * ($grid * 2)) + ($inside-columns-auto * $grid);

.is-cols,
.is-cols--outside {
  margin-right: - (percentage($outside-gap / $outside-grid-auto));
  margin-left: - (percentage($outside-gap / $outside-grid-auto));

  > .is-col1,
  > .is-col2,
  > .is-col3,
  > .is-col4,
  > .is-col5,
  > .is-col6,
  > .is-col7,
  > .is-col8,
  > .is-col9,
  > .is-col10,
  > .is-col11,
  > .is-col12 {
    margin-left: percentage($outside-gap / $outside-grid-auto);
    margin-right: percentage($outside-gap / $outside-grid-auto);
    margin-bottom: $outside-bottom;

    > *:last-child {
      margin-bottom: 0 !important;
    }

    > .is-cols--inside {
      margin-bottom: -$inside-bottom;
    }

  }
}

.is-cols--inside {
  margin-right: - (percentage($inside-gap / $inside-grid-auto));
  margin-left: - (percentage($inside-gap / $inside-grid-auto));

  > .is-col1,
  > .is-col2,
  > .is-col3,
  > .is-col4,
  > .is-col5,
  > .is-col6,
  > .is-col7,
  > .is-col8,
  > .is-col9,
  > .is-col10,
  > .is-col11,
  > .is-col12 {
    margin-left: percentage($inside-gap / $inside-grid-auto);
    margin-right: percentage($inside-gap / $inside-grid-auto);
    margin-bottom: $inside-bottom;

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.is-cols--gapless {
  margin-right: 0;
  margin-left: 0;

  > .is-col1,
  > .is-col2,
  > .is-col3,
  > .is-col4,
  > .is-col5,
  > .is-col6,
  > .is-col7,
  > .is-col8,
  > .is-col9,
  > .is-col10,
  > .is-col11,
  > .is-col12 {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.is-cols,
.is-cols--outside,
.is-cols--inside,
.is-cols--gapless {
  @include clearfix;

  > .is-col1,
  > .is-col2,
  > .is-col3,
  > .is-col4,
  > .is-col5,
  > .is-col6,
  > .is-col7,
  > .is-col8,
  > .is-col9,
  > .is-col10,
  > .is-col11,
  > .is-col12 {
    min-height: 1px;
    float: left;
    box-sizing: border-box;
    max-width: 100%;
  }
}

@include media-sp-max {
  .is-cols--break {

    margin-right: 0;
    margin-left: 0;

    > .is-col1,
    > .is-col2,
    > .is-col3,
    > .is-col4,
    > .is-col5,
    > .is-col6,
    > .is-col7,
    > .is-col8,
    > .is-col9,
    > .is-col10,
    > .is-col11,
    > .is-col12 {
      width: auto;
      float: none;
      @include clearfix;
      clear: both;
      margin-left: 0;
      margin-right: 0;

      margin-bottom: $width-gap-sp !important;
    }

    margin-bottom: 0 !important;

  }
}

@for $i from 1 through 12 {
  .is-cols,
  .is-cols--outside {

    $this-grid: $i;
    $this-width: ((($outside-columns-auto * $this-grid) + ($outside-gap * ($this-grid - 1)) + ($outside-gap * ($this-grid - 1))) / $outside-grid-auto * 100%);

    > .is-col#{$i} {
      width: $this-width;
    }

    > .is-offset#{$i} {
      margin-left: $this-width + (percentage($outside-gap / $outside-grid-auto) * 3);
    }
  }

  .is-cols--inside {

    $this-grid: $i;
    $this-width: ((($inside-columns-auto * $this-grid) + ($inside-gap * ($this-grid - 1)) + ($inside-gap * ($this-grid - 1))) / $inside-grid-auto * 100%);

    > .is-col#{$i} {
      width: $this-width;
    }

    > .is-offset#{$i} {
      margin-left: $this-width + (percentage($inside-gap / $inside-grid-auto) * 3);
    }
  }

  .is-cols--gapless {

    $this-grid: $i;

    > .is-col#{$i} {
      width: 8.333333333% * $i;
    }

    > .is-offset#{$i} {
      margin-left: 8.333333333% * $i;
    }

  }

}

