@charset "utf-8";

/* ==========================================================================
   #HEADING
   ========================================================================== */

/* 見出し1
   ========================================================================== */

/*doc
---
title: Heading
name: heading1
category: heading
---

```html_example
<h1 class="e-heading1">情に棹させば流される智に働けば角が立つ</h1>
<h2 class="e-heading2">情に棹させば流される智に働けば角が立つ</h2>
<h3 class="e-heading3">情に棹させば流される智に働けば角が立つ</h3>
<h4 class="e-heading4">情に棹させば流される智に働けば角が立つ</h4>
<h5 class="e-heading5">情に棹させば流される智に働けば角が立つ</h5>
<h6 class="e-heading6">情に棹させば流される智に働けば角が立つ</h6>

```

*/

.rich-editor h1,
.rich-editor h2,
.rich-editor h3,
.rich-editor h4,
.rich-editor h5,
.rich-editor h6,
.e-heading1,
.e-heading2,
.e-heading3,
.e-heading4,
.e-heading5,
.e-heading6 {
  margin-bottom: $fsize-mb-pc;
  font-weight: bold;
  overflow: hidden;
  line-height: $heading-line-height-pc;

  .l-primary-block & {
    // width: ($screen-main-colum / $screen-pc-max) * 100%;
  }
}

.rich-editor h2:not(:first-child),
.rich-editor h3:not(:first-child),
.rich-editor h4:not(:first-child),
.rich-editor h5:not(:first-child),
.rich-editor h6:not(:first-child) {
  margin-top: 2.6rem;

  @include media-sp-max {
    margin-top: 0;
  }
}

//連続するタイトルは狭める
.rich-editor h1 + h2,
.rich-editor h2 + h2,
.rich-editor h2 + h3,
.rich-editor h3 + h3,
.rich-editor h3 + h4,
.rich-editor h4 + h4,
.rich-editor h4 + h5,
.rich-editor h5 + h5,
.rich-editor h5 + h6,
.rich-editor h6 + h6 {
  margin-top: 0 !important;
}

.rich-editor h1,
.e-heading1 {
  @include fsize($fsize-h1-pc, false, $fsize-h1-sp);
}

.rich-editor h1,
.e-heading1--type2 {

  position: relative;
  border-bottom: 1px solid $border-color;
  padding-bottom: 1rem;
  margin-bottom: 2.5rem;

  @include media-sp-max {
    margin-bottom: 1.4rem;
  }

  &:before {
    padding-top: 1.375rem;
    display: block;
    content: "";

    @include media-sp-max {
      padding-top: 1.15rem;
    }
  }

  &:after {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, $brand-secondary 35%, $brand-primary 35%);
    display: block;
    height: 3px;
    content: "";
  }

  @include media-sp-max {
    margin-right: -$width-gap-sp;
    margin-left: -$width-gap-sp;
    padding-left: $width-gap-sp;
    padding-right: $width-gap-sp;
  }

  .is-staff {
    background-color: $brand-light-green;
    padding: .6em 1em;
    color: #fff;
    @include fsize(16px, false, 14px);
    border-radius: 0 0 $border-radius-pc $border-radius-pc;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1;

    @include media-sp-max {
      border-radius: 0 0 0 $border-radius-pc;
    }

    i {
      top: 2px;
      position: relative;
    }

  }

}

.rich-editor h2,
.e-heading2 {
  @include fsize($fsize-h2-pc, false, $fsize-h2-sp);

}

.rich-editor h2,
.e-heading2--type2 {
  color: #fff;
  background-color: $brand-primary-light;
  padding: .7rem 1.2rem .6rem;
  margin-bottom: 1.9rem;

  a {
    color: inherit!important;
  }

  @include media-sp-max {
    margin-bottom: 1.4rem;
  }
}

.rich-editor h3,
.e-heading3,
.e-heading3--type2 {
  @include fsize($fsize-h3-pc, false, $fsize-h3-sp);
}

.rich-editor h3,
.e-heading3--type2 {
  border-left: 5px solid $brand-secondary;
  border-bottom: 1px solid #757575;
  padding-bottom: .6em;
  padding-left: .9em;
  padding-top: .5em;
  @include mb-small;

  @include media-sp-max {
    padding-bottom: .8em;
    padding-left: 1.1em;
    padding-top: .7em;
  }
}

.rich-editor h4,
.e-heading4,
.e-heading4--type2 {
  @include fsize($fsize-h4-pc, false, $fsize-h4-sp);
}

.rich-editor h4,
.e-heading4--type2 {

  border-left: 3px solid $brand-primary;
  padding-left: 1rem;
  padding-top: .1em;
  padding-bottom: .1em;
  margin-bottom: .8rem;
}

.rich-editor h5,
.e-heading5 {
  @include fsize($fsize-h5-pc, false, $fsize-h5-sp);
}

.rich-editor h6,
.e-heading6 {
  @include fsize($fsize-h6-pc, false, $fsize-h6-sp);
}

/*doc
---
title: Heading
name: heading2
category: heading
---

```html_example
<h1 class="e-heading1 e-heading1--type2"><span>情に棹させば流される智に働けば角が立つ</span></h1>
<h2 class="e-heading2 e-heading2--type2">情に棹させば流される智に働けば角が立つ</h2>
<h2 class="e-heading2 e-heading2--type2"><a href="">情に棹させば流される智に働けば角が立つ（リンク有り）</a></h2>
<h3 class="e-heading3 e-heading3--type2">情に棹させば流される智に働けば角が立つ</h3>
<h4 class="e-heading4 e-heading4--type2">情に棹させば流される智に働けば角が立つ</h4>
<h5 class="e-heading5 e-hd--brand">情に棹させば流される智に働けば角が立つ</h5>
<h6 class="e-heading6 e-hd--bd">情に棹させば流される智に働けば角が立つ</h6>

```

*/

.e-hd--brand {
  color: $brand-primary;
}

.e-hd--bd {
  border-bottom: 1px solid $brand-primary;
}

/*doc
---
title: Heading Unit
name: heading3
category: heading
---

```html_example


<div class="e-heading-unit">
  <h3 class="e-heading3">情に棹させば流される智に働けば角が立つ</h3>
  <p class="is-text-week"> 6月12日  12:49</p>
</div>


<div class="e-heading-unit">
  <h2 class="e-heading2">情に棹させば流される智に働けば角が立つ</h2>
  <p class="e-heading-unit__to-list"><a href="">一覧</a></p>
</div>


```
*/

.e-heading-unit {
  position: relative;
  padding-right: 4em;
  @include mb-small;

  h1:not(.hd--bd),
  h2:not(.hd--bd),
  h3:not(.hd--bd),
  h4:not(.hd--bd),
  h5:not(.hd--bd),
  h6:not(.hd--bd) {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__to-list {
    position: absolute;
    right: 0;
    top: 0;
    a {
      border-radius: $border-radius-pc;
      background-color: #eef0f4;
      padding: .3em .7em;
      display: inline-block;
      color: $text-color;
      @include fsize(14px);
      @include elevation-transition;

      &:hover {
        text-decoration: none;
        @include elevation(4);
      }
      &:active {
        text-decoration: none;
        @include elevation(8);
      }

    }

  }

}


