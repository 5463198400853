@charset "utf-8";

/* Adobe Acrobat Reader

   ========================================================================== */

/*doc
---
title: Adobe Acrobat Reader
name: snippets-acrobat-reader
category: snippets-other
---

```html_example
<div class="_snippets-acrobat-reader">
  <p class="_text">PDFファイルをご覧いただくためには、Adobe Acrobat Readerが必要です。アドビ社のサイトより無料でダウンロード可能です。</p>
  <div class="_img"><img src="/assets/img/common/get_adobe_acrobat_reader.png" alt=""></div>
</div>

```
*/

._snippets-acrobat-reader {

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid $border-color;

  @include media-sp-max {
    display: block;
  }

  ._text {
    margin-right: 12px;
    @include fsize(14px);
    color: $text-color-week;
    margin-bottom: 0;

    @include media-sp-max {
      margin-bottom: 16px;
    }
  }

  ._img {

    a {
      display: inline-block;
    }
    margin: auto;
    text-align: center;
    min-width: 158px;
    width: 158px;
  }

}

//旧コンテンツ用
.getAdobeReader {
  padding: 1rem;
  border: 1px solid $border-color;
  @include fsize(14px);
  color: $text-color-week;

  @include mb-small();

  a {
    float: right;
    display: inline-block;
    margin: auto;
    margin-left: 10px;
    text-align: center;
    min-width: 158px;
    width: 158px;

    @include media-sp-max {
      display: block;
      float: none;
      margin-bottom: 10px;
    }
  }
  a:after {
    display: none!important;
  }
}
