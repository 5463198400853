@charset "utf-8";

/* Control
   ========================================================================== */
/*doc
---
title:
name: form-control
category: form
---





```html_example

<h3 class="styleguide">Text Input</h3>
<input type="text"  class="e-form-control">

<h3 class="styleguide">Textarea</h3>
<textarea cols="40" rows="10" class="e-form-control"></textarea>

<h3 class="styleguide">Disabled Input</h3>
<input type="text" disabled class="e-form-control">


<h3 class="styleguide">Input with Success</h3>
<div class="is-valid"><input type="text"  class="e-form-control"></div>

<h3 class="styleguide">Input with Warning</h3>
<div class="is-warning"><input type="text"  class="e-form-control"></div>

<h3 class="styleguide">Input with Error</h3>
<div class="is-error"><input type="text"  class="e-form-control"></div>


<h3 class="styleguide">Select Menu</h3>
<select class="e-form-control">
  <option value="" >都道府県</option>
  <option value="福岡県">福岡県</option>
  <option value="...">...</option>
</select>


<h3 class="styleguide">Checkbox</h3>
<ul class="e-form-rc-box e-form-rc-box--ib" id="checkbox-assy">
  <li><input type="checkbox" class="e-form-control" id="checkbox1"><label for="checkbox1">default</label></li>
  <li><input type="checkbox" disabled class="e-form-control" id="checkbox2"><label for="checkbox2">disabled</label></li>
  <li><input type="checkbox" checked class="e-form-control" id="checkbox3"><label for="checkbox3">checked</label></li>
  <li><input type="checkbox" disabled checked class="e-form-control" id="checkbox4"><label for="checkbox4">checked & disabled</label></li>
</ul>

<h3 class="styleguide">Radio Buttons</h3>
<ul class="e-form-rc-box e-form-rc-box--ib" id="radio-assy">
  <li><input type="radio" class="e-form-control" id="radio1"><label for="radio1">default</label></li>
  <li><input type="radio" class="e-form-control" disabled id="radio2"><label for="radio2">disabled</label></li>
  <li><input type="radio" class="e-form-control" checked id="radio3"><label for="radio3">checked</label></li>
  <li><input type="radio" class="e-form-control" disabled checked id="radio4"><label for="radio4">checked & disabled</label></li>
</ul>



```



*/

$form-field-bg: $brand-white;
$form-field-bg-hover: tint($form-field-bg, 40%);
$form-field-border: $border-color;
$form-field-radius: $border-radius-pc;
$form-field-shadow: inset .3px .3px 2px rgba(0, 0, 0, .1);

.e-form-control {
  padding: 10px 15px;
  transition: border-color $animation, box-shadow $animation;
  border: 1px solid $form-field-border;
  border-radius: $form-field-radius;
  background-color: $form-field-bg;
  box-shadow: $form-field-shadow;
  color: $text-color;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;

  &:focus,
  &:hover {
    background-color: $form-field-bg-hover;

  }


  &:focus {
    box-shadow: 0 0 3px #3e94cf, 0 0 7px #3e94cf;
    //border-color: $brand-primary;
    outline: 0;
  }

  &::-webkit-input-placeholder {
    color: #868686;
  }

  &::-moz-input-placeholder {
    color: #868686;
  }

  &::-ms-input-placeholder {
    color: #868686;
  }

  &::-ms-expand {
    display: none;
  }

}

input[type=file].e-form-control {
  display: none;

  ~ label {
    padding: 10px 15px;
    transition: border-color $animation, box-shadow $animation;
    border: 1px solid $form-field-border;
    border-radius: $form-field-radius;
    background-color: $form-field-bg;
    box-shadow: $form-field-shadow;
    box-sizing: border-box;
    width: 100%;
    display: block;
    color: #868686;
  }
}





.e-form-control[disabled],
.e-form-control[readonly],
fieldset[disabled] .e-form-control {
  background-color: #eee;
  cursor: not-allowed;
}

textarea.e-form-control {
  height: auto;
  resize: vertical;
}


select.e-form-control {
  background: $form-field-bg url("#{$img-path}/form/form_icon_select_top.svg") right 10px center no-repeat;
  text-align: left;
  text-indent: .01px;
  text-overflow: "";
  cursor: pointer;

  &:focus {
    background-image: url("#{$img-path}/form/form_icon_select_top.svg");
  }
}

//radio, checkbox

.e-form-rc-box {
  position: relative;

  label {
    display: inline-block;
    position: relative;
    min-height: 20px;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
    box-sizing: border-box;
    //width: 100%;
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    width: auto;

    + label:before {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      width: 20px;
      height: 20px;
      background: url("#{$img-path}/form/form_icons.png") 0 0 no-repeat;
      background-image: url("#{$img-path}/form/form_icons.svg");
      content: "";
      overflow: hidden;
    }
  }

  input:focus + label {
    box-shadow: 0 0 3px #3e94cf, 0 0 7px #3e94cf;
  }

  input:disabled + label {
    opacity: .6;
  }

  input:checked + label {
    color: #333;
  }

  //radio
  input[type=radio] + label:before {
    background-position: -40px -40px;
  }

  input[type=radio]:disabled + label:before {
    background-position: -40px -70px;
  }

  input[type=radio]:checked + label:before {
    background-position: -140px -40px;
  }

  input[type=radio]:disabled:checked + label:before {
    background-position: -140px -70px;
  }

  //checkbox
  input[type=checkbox] + label:before {
    background-position: -40px -140px;
  }

  input[type=checkbox]:disabled + label:before {
    background-position: -40px -110px;
  }

  input[type=checkbox]:checked + label:before {
    background-position: -140px -140px;
  }

  input[type=checkbox]:disabled:checked + label:before {
    background-position: -140px -110px;
  }

}

.e-form-rc-box--ib > div,
.e-form-rc-box--ib > li,
.e-form-rc-box--ib > span {
  display: inline-block;
}

//状態

.e-form-error-text,
.e-form-valid-text,
.e-form-warning-text {
  display: none;
}

.is-error {

  .e-form-error-text {
    display: block;
  }

  .e-form-control,
  .e-form-rc-box {
    border-color: $brand-red;
    color: $brand-red;
    background: $form-field-bg url("#{$img-path}/form/form_icon_error.svg") 98% .6em no-repeat;
    padding-right: 30px;
    background-color: #fff0f5;
  }
}


.is-warning {

  .e-form--warning-text {
    display: block;
  }

  .e-form-control,
  .e-form-rc-box {
    border-color: $brand-orange;
    color: $brand-orange;
    background: $form-field-bg url("#{$img-path}/form/form_icon_warning.svg") 98% .6em no-repeat;
    padding-right: 30px;
  }

}


.is-valid {
  //color: #8cc152;

  .e-form--valid-text {
    display: block;
  }

  .e-form-control,
  .e-form-rc-box {
    border-color: #8cc152;
    background: $form-field-bg url("#{$img-path}/form/form_icon_done.svg") 98% .8em no-repeat;
    padding-right: 30px;
  }

}

//a11y
[role="tooltip"] {
  display: none;
  width: auto;
  margin: 0;
  padding: .25em;
  background: $brand-primary;
  color: #fff;
  z-index: 2;

  //display: block;
  position: static;
  top: 100%;
}

.e-form-control:focus + [role="tooltip"] {
  display: block;
  position: static;
  top: 100%;
}

//フォーム内インラインフレーム
.e-form-inframe {
  width: 100%;
  max-height: 250px;
  margin: 15px auto;
  padding-right: 15px;
  background-color: $form-field-bg;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid $border-color;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #d4d4d4;
  }

  &::-webkit-scrollbar-thumb {
    background: #000;
  }

}
