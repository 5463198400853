@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../iconfont/MaterialIcons-Regular.eot);
  src: local('Material Icons'), local('MaterialIcons-Regular'),
  url(../iconfont/MaterialIcons-Regular.woff2) format('woff2'),
  url(../iconfont/MaterialIcons-Regular.woff) format('woff'),
  url(../iconfont/MaterialIcons-Regular.ttf) format('truetype')
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: 400;
  font-style: normal;
  //font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';

  font-size: inherit;
  display: none\9;
  @media all and (-ms-high-contrast:none){
    display: inline-block\0;
  }
}

