@charset "utf-8";

/* ==========================================================================
   #Menu nav
   ========================================================================== */

/*doc
---
title: Menu Nav
name: menu-nav
category: menu-nav
---


sp時アコーディオン

`.c-menu-nav--bordered`
<br>`.c-menu-nav--large`
<br>`.c-menu-nav__body--one-third`,`.c-menu-nav__body--inline`
<br>
<br>

```html_example
 <div class="c-menu-nav c-menu-nav--bordered c-menu-nav--large" data-accordion data-device-accordion="sp">
  <div class="c-menu-nav__head c-menu-nav__head--accordion"  aria-controls="accordion2" aria-expanded="true" aria-label="開く" data-toggle-accordion>
      <div class="c-menu-nav__title"><a href="">タイトル <i class="material-icons">launch</i></a></div>
      <p class="c-menu-nav__text">概要文（ディスクリプション）が入ります。</p>
  </div>
    <ul class="c-menu-nav__body is-hidden--sm" id="accordion2"  aria-hidden="true" data-body-accordion>
      <li><a href="">タイトル</a> </li>
      <li><a href="">タイトル</a> </li>
      <li><a href="">タイトル</a> </li>
      <li><a href="">タイトル</a> </li>
    </ul>
  </div>



   <div class="c-menu-nav" data-accordion data-device-accordion="sp">
  <div class="c-menu-nav__head c-menu-nav__head--accordion"  aria-controls="accordion3" aria-expanded="true" aria-label="開く" data-toggle-accordion>
      <div class="c-menu-nav__title"><a href="">タイトル</a></div>
  </div>
    <ul class="c-menu-nav__body is-hidden--sm" id="accordion3"  aria-hidden="true" data-body-accordion>
      <li><a href="">タイトル</a> </li>
      <li><a href="">タイトル</a> </li>
      <li><a href="">タイトル</a> </li>
      <li><a href="">タイトル</a> </li>
    </ul>
  </div>

```
*/


.c-menu-nav {
  border-bottom: 1px solid $border-color;

  @include mb-small;

  &__head {
    border: 1px solid $border-color;
    border-bottom: 0;
    display: block;
    background: #eef0f4;
    border-radius: 0;
    padding: .6rem 1rem;
    @include media-sp-max {
      padding-right: 2.5rem;
    }
  }
  //アコーディオン時
  &__head--accordion {
    position: relative;

    &:after {
      color: $text-color-sub;
      position: absolute;
      top: 0;
      bottom: 0;
      @include fsize(20px);
      right: $width-gap-sp;
      margin: auto;
      height: 1em;
      @include material-icons;
      vertical-align: middle;
      content: "add";
      white-space: nowrap;
      line-height: 1em;
      display: none;

      @include media-sp-max {
        display: block;
      }
    }

    &[aria-expanded="false"]:after {
      content: "remove";
    }

    @include media-sp-max {
      a {
        pointer-events: none;
        color: $text-color;
      }
    }

  }

  &__title {
    font-weight: bold;
    @include fsize(18px, false, 16px);

    span {
      display: inline-block;
    }

    + .c-menu-nav__text {
      @include media-sp-max {
        margin-top: .3em;
      }
    }
  }

  &__text {
    @include fsize(14px);
    color: $text-color-sub;
  }

  &__body {

    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 1rem;

    @include media-sp-max {
      display: block;
    }

    li {
      width: 100%;
      padding-right: 2.5%;
      line-height: 1.5;
      margin-bottom: .3em;
      box-sizing: border-box;
      @include media-sp-max {
        width: auto;
        padding-right: 0;
      }
    }

    a {
      text-indent: -.6em;
      padding-left: 1em;
      @include fsize(14px);
      display: inline-block;
      &:before {
        color: $border-color;
        @include material-icons;
        vertical-align: middle;
        content: "navigate_next";
        white-space: nowrap;
        font-size: 1.2em;
      }

      &:hover:before {
        text-decoration: none;
      }
    }

  }

}

.c-menu-nav--bordered {

  .c-menu-nav__head {
    border-left: 5px solid $brand-secondary;
    background: #f7f7f7;
  }

}

.c-menu-nav--large {
  .c-menu-nav__head {
    padding: 1.1rem 1.5rem;
    @include media-sp-max {
      padding: .8rem 2.5rem .8rem 1.1rem;
    }
  }

  .c-menu-nav__title {
    @include fsize(22px, false, 16px);
  }

  .c-menu-nav__body {
    a {
      @include fsize(16px, false, 14px);
    }
  }
}

.c-menu-nav__body--one-third {
  li {
    width: 33.333%;
    @include media-sp-max {
      width: auto;
    }
  }
}

.c-menu-nav__body--inline {
  li {
    width: auto;
    @include media-sp-max {
      width: auto;
    }
  }
}

