@charset "utf-8";

/* @  container Layout
 * ------------------------------------------------------------ */

/*doc
---
title:
name: layout
category: layout
---

<p>メインカラムレイアウトを下記のパターンから選択します。</p>

<p>スマホ時はgap幅が追加されます。レイアウトで最大幅にしたい要素に対して<code>.is-gapless--sm</code>,<code>.is-gapless--md</code>を指定してください。</p>


<h2 class="styleguide">1column</h2>
```html_example
<div class="l-container">
  <div class="l-container__inner"><div class="dummy-box">
    <p>.l-container > .l-container__inner</p>
      <div class="l-content-block"><div class="dummy-box">.l-content-block</div></div>
  </div>
  </div>
</div>
```

<h2 class="styleguide">1column-full</h2>
```html_example
<div class="l-container">

    <div class="l-container__inner"><div class="dummy-box">
    <p>.l-container > .l-container__inner</p>
      <div class="l-content-block l-content-block-full"><div class="dummy-box">.l-content-block-full</div></div>
  </div>
    </div>
  </div>
```
```html_example
    <div class="l-container">
    <div class="l-container__inner-full"><div class="dummy-box">
    <p>.l-container > .l-container__inner-full</p>
     <div class="l-content-block l-content-block-full"><div class="dummy-box">.l-content-block-full</div></div>
    </div>
   </div>
  </div>
```


<h2 class="styleguide">2column</h2>
```html_example
<div class="l-container">
  <div class="l-container__inner"><div class="dummy-box">
    <p>.l-container > .l-container__inner</p>
      <div class="l-primary-block"><div class="dummy-box">.l-primary-block</div></div>
      <div class="l-secondary-block"><div class="dummy-box">.l-secondary-block</div></div>
  </div>
  </div>
</div>
```
*/

.l-container {
  min-height: 2px;
  z-index: 1;

  &--padding {
    padding-top: $margin-medium-pc;
    padding-bottom: $margin-medium-pc;

    @include media-sp-max {
      padding-top: $margin-medium-sp;
      padding-bottom: $margin-medium-sp;
    }
  }
}
.l-container--bg-color {
  padding-top: 38px;
  padding-bottom: 16px;
  background-color: $bg-color;

  margin-bottom: 50px;

  @include media-sp-max {
    padding-top: 23px;
    padding-bottom: 10px;
    margin-bottom: 37px;

  }

}




.l-container__inner {
  @include clearfix;
  max-width: $width-pc-max;
  margin-left: auto;
  margin-right: auto;
  padding-right: $width-gap-pc;
  padding-left: $width-gap-pc;

  @include media-tab-max {
    padding-right: $width-gap-tab;
    padding-left: $width-gap-tab;
  }

  @include media-sp-max {
    padding-right: $width-gap-sp;
    padding-left: $width-gap-sp;
  }

}

.l-container__inner-full {
  @include clearfix;
}

