@charset "utf-8";

/* ==========================================================================
   #card
   ========================================================================== */

/*doc
---
title:
name: card
category: card
---

<h3 class="styleguide">Default</h3>

```html_example

<div class="c-card">
  <a href="">
    <div class="c-card__img"><img src="/assets/img/dummy/540x304.png" alt=""></div>
    <div class="c-card__body">
      <p class="c-card__title">情に棹させば流される智に働けば角が立つ</p>
      <p class="c-card__text">情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。</p>
    </div>
  </a>
</div>

```

*/

.c-card {
  //max-width: 400px;
  color: inherit;
  background-color: #fff;
  margin-right: auto;
  margin-left: auto;
  @include mb-small;

  @include media-sp-max {
    max-width: inherit;
  }

  > a {
    display: block;
    color: inherit;
    height: inherit;
    text-decoration: none;

    @include elevation(2);
    @include elevation-transition;

    &:hover,
    &:focus,
    &.is-hover {
      @include elevation(4);
    }

    &:active {
      @include elevation(8);
    }
  }

  &__img {
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    position: relative;
    overflow: hidden;
    width: auto;

    //height: 102px;
    //height: 138px; top
    //@include media-sp-max {
    //  height: 92px;
    //  height: 125px;
    //}

    img {
      //object-fit: cover;
      //position: absolute;
      //top: 0;
      //left:0;
      //bottom:0;
      //right:0;
      //margin: auto;
    }
  }

  //&__img--natural {
  //
  //  position: static;
  //  height: auto;
  //  @include media-sp-max {
  //    height: auto;
  //  }
  //
  //  img {
  //    position: static;
  //  }
  //
  //}

  &__body {
    padding: .6rem 1rem;
    position: relative;
  }

  &__label {
    position: absolute;
    right: 1rem;
    top: .6rem;
    margin-bottom: .3rem;
    line-height: 1;

    .e-label {
      margin-right: .3em;
    }

    @include media-sp-max {
      position: static;
    }

  }

  &__title,
  &__text {
    margin-bottom: .3rem;
  }

  &__title {
    font-weight: bold;
    line-height: 1.4;
    @include fsize(16px, false, 14px);
  }

  &__text {
    + .c-card__text {
      margin-top: $margin-small-pc;

      @include media-sp-max {
        margin-top: $margin-small-sp;
      }
    }
  }

}

.c-card--small {

  .c-card__title {
    @include fsize(14px, false, 14px);
  }

}

/*doc
---
title:
name: card-bg
category: card
---

<h3 class="styleguide">with Background</h3>
```html_example

<div class="c-card c-card--bg">
  <a href="">
    <div class="c-card__body">
      <p class="c-card__title">情に棹させば流される智に働けば角が立つ</p>
      <p class="c-card__text">情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。</p>
    </div>
    </a>
</div>

```

*/

.c-card--bg {
  background-color: $bg-color;
}


