@charset "utf-8";

/* ==========================================================================
   #PATTERN
   ========================================================================== */

/*doc
---
title: Layer
name: texture
category: toolstexture
---


<ul class="sg-colors">
  <li>
    <span class="sg-swatch is-layer-dot"></span>
    <span class="sg-label">Layer Dot</span>
    <span class="sg-label-sub">.is-layer-dot > &__float-chamber{-abs}</span>
  </li>
  <li>
    <span class="sg-swatch is-layer-black"></span>
    <span class="sg-label">Layer Black</span>
    <span class="sg-label-sub">.is-layer-black > &__float-chamber{-abs}</span>
  </li>
</ul>


*/

//画像の上に敷くドット
.is-layer-dot {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background-color: rgba(0, 0, 0, .4);
    background-image: radial-gradient(#000 20%, transparent 0), radial-gradient(#000 20%, transparent 0);
    background-position: 0 0, 10px 10px;
    background-size: 4px 4px;
  }

  &__float-chamber {
    position: relative;
    z-index: 99;
  }

  &__float-chamber-abs {
    position: absolute;
    z-index: 99;
  }
}

//画像の上に敷く半透明黒
.is-layer-black {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background-color: rgba(0, 0, 0, .4);
    background-position: 0 0, 10px 10px;
  }

  &__float-chamber {
    position: relative;
    z-index: 99;
  }

  &__float-chamber-abs {
    position: absolute;
    z-index: 99;
  }
}

/*doc
---
title: Gradient to Stripe
name: texture1
category: toolstexture
---

<ul class="sg-colors">
  <li>
    <span class="sg-swatch is-bg-gradient"></span>
    <span class="sg-label">Gradient</span>
    <span class="sg-label-sub">.is-bg-gradient</span>
  </li>
  <li>
    <span class="sg-swatch is-bg-stripe"></span>
    <span class="sg-label">Stripe</span>
    <span class="sg-label-sub">.is-bg-stripe</span>
  </li>
</ul>


*/

.is-bg-gradient {
  background-image: linear-gradient(to bottom, darken($brand-white, 20%) 0%, $brand-white 100%);
}

.is-bg-stripe {
  background: repeating-linear-gradient(-45deg, #f4f4f4, #f4f4f4 3px, #fff 0, #fff 5px);
}

/*doc
---
title: Pattern
name: texture2
category: toolstexture
---

<ul class="sg-colors">
  <li>
    <span class="sg-swatch is-pattern-bg1"></span>
    <span class="sg-label">Pattern1</span>
    <span class="sg-label-sub">.is-pattern-bg1</span>
  </li>
  <li>
    <span class="sg-swatch is-pattern-bg2"></span>
    <span class="sg-label">Pattern2</span>
    <span class="sg-label-sub">.is-pattern-bg2</span>
  </li>
</ul>

*/

.is-pattern-bg1 {
  background: url("#{$img-path}/common/pattern_bg1.png") center center repeat;
}

.is-pattern-bg2 {
  background: url("#{$img-path}/common/pattern_bg2.png") center center repeat;
}
