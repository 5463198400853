@charset "utf-8";

/* 合格発表（発表時の前後のみ表示）タイマーで日時設定

   ========================================================================== */

/*doc
---
title: notice-box
name: notice-box
category: snippets-other
---

```html_example

<div class="_snippets-notice-box">
  <a href="" class="_body">タイマーで日時設定</a>
</div>

```
*/

._snippets-notice-box {
  ._body {
    display: block;
    border-radius: $border-radius-pc;
    @include mb-small;
    background-color: $brand-orange;
    text-align: center;
    @include fsize(20px, false, 16px);
    font-weight: bold;

    color: #fff !important;
    padding: 12px 16px 17px;

    @include elevation(2);
    @include elevation-transition;

    &:hover,
    &:focus {
      text-decoration: none;
      @include elevation(4);
    }

    &:active {
      text-decoration: none;
      @include elevation(8);
    }
  }

  @include media-sp-max {
    ._body {
      padding: 16px;
    }
  }

}


