@charset "utf-8";

/* Menu List

   ========================================================================== */

/*doc
---
title: Menu List
name: menu-list3to1
category: snippets-other
---

```html_example
    <ul class="_snippets-menu-list3to1">
      <li><a href="">タイトル</a> </li>
      <li><a href="">タイトル</a> </li>
      <li><a href="">タイトル</a> </li>
      <li><a href="">タイトル</a> </li>
      <li><a href="">タイトル</a> </li>
    </ul>

```
*/

._snippets-menu-list3to1 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  @include mb-small;

  @include media-sp-max {
    display: block;
    border: 1px solid $border-color;
  }

  li {
    width: 32%;
    padding-right: 1.3333%;
    margin-bottom: .5em;

    @include media-sp-max {
      padding-right: 0;
      margin-bottom: 0;
      width: auto;
      border-bottom: 1px solid $border-color;
      &:last-child {
        border-bottom-width: 0;
      }
    }
  }

  a {
    text-indent: -.6em;
    padding-left: 1em;
    display: inline-block;
    @include media-sp-max {
      display: block;
      padding: 1em 1em 1em 2.2em;
    }

    &:before {
      color: $border-color;
      @include material-icons;
      vertical-align: middle;
      content: "navigate_next";
      white-space: nowrap;
      font-size: 1.2em;
    }

    &:hover:before {
      text-decoration: none;
    }
  }
}