@charset "utf-8";

/* @ スタイルガイド用
 * ------------------------------------------------------------ */

.sg-html {
  margin-top: 0;
  @include media-sp-max {
    margin-top: 0;
  }

}

.sg-body {
  background-color: #fff;
}

.sg-header {
  background: $brand-primary;

  &__inner {
    padding-top: 25px;
    //padding-bottom: 28px;
    @include clearfix;
    max-width: $width-pc-max;
    margin-left: auto;
    margin-right: auto;
    padding-right: $width-gap-pc;
    padding-left: $width-gap-pc;
  }

  h1 {
    line-height: 1;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #fff;
  }
}

.sg-header__nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-left: -16px;
  a {
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 64px;
    padding: 0 16px;
    color: #fff;
    box-sizing: border-box;
    border-bottom: 3px solid transparent;
    opacity: .65;
    text-decoration: none;
  }

  a:hover {
    opacity: 1;
  }
  .current {
    opacity: 1;
    border-bottom-color: $brand-primary-light;
  }
}

.sg-footer {
  background: $brand-primary;

  &__inner {
    @include clearfix;
    padding-top: 19px;
    padding-bottom: 17px;
    max-width: $width-pc-max;
    margin-left: auto;
    margin-right: auto;
    padding-right: $width-gap-pc;
    padding-left: $width-gap-pc;
  }

  p {
    line-height: 1;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
}

.sg-widget {
  margin-bottom: 40px;
  font-size: 16px;

  a {
    text-decoration: none;
    color: $text-color-sub;

    &:hover {
      transition: all $animation;
      text-decoration: underline;
    }
  }

  &__title {
    color: $text-color;
    font-size: 18px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 15px;
    padding-bottom: 7px;
  }
}

.sg-widget-list {
  padding-left: 1em;
  &__item {
    text-indent: -1em;
    padding-left: 1em;
    margin-bottom: 10px;
  }
}

.sg-container {
  @include clearfix;
  max-width: $width-pc-max;
  margin-left: auto;
  margin-right: auto;
  padding-right: $width-gap-pc;
  padding-left: $width-gap-pc;
  margin-top: 20px;
  margin-bottom: 60px;
  @include media-sp-max {
    width: auto;
    margin-bottom: 20px;
  }
}

.sg-content-block {
  width: ($screen-main-wide-colum / $screen-pc-max) * 100%;
  margin-left: auto;
  margin-right: auto;

  @include media-tab-max {
    width: 100%;
  }

  @include media-sp-max {
    width: 100%;
  }
}

.sg-primary-block {
  width: ($screen-main-colum / $screen-pc-max) * 100%;
  float: right;

  @include media-sp-max {
    float: none;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

}

.sg-secondary-block {
  float: left;
  width: ($screen-sub-colum / $screen-pc-max) * 100%;
  padding-right: 60px;
  box-sizing: border-box;

  @include media-sp-max {
    float: none;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    display: none;
  }
}

.sg-heading2 {
  font-size: 26px;
  font-weight: 700;

  color: $brand-primary;
}

.sg-heading3 {
  font-size: 16px;
  margin: 1.5em 0 1em;
}

.sg-text {
  font-size: 14px;
  margin: 1.5em 0 1em;
  color: #aab2bd;
}

.sg-main-contents {
  @include clearfix;
  max-width: $width-pc-max;
  margin-left: auto;
  margin-right: auto;
  padding-right: $width-gap-pc;
  padding-left: $width-gap-pc;

  @include media-tab-max {
    padding-right: $width-gap-tab;
    padding-left: $width-gap-tab;
  }

  @include media-sp-max {
    padding-right: $width-gap-sp;
    padding-left: $width-gap-sp;
  }
}

.dummy-box {
  width:100%;
  display: block;
  padding: 5px 0;
  //background-color: #fff1f0;
  background-color: rgba(216, 202, 201, .4);
  text-align: center;
  font-size: 12px;
  @include clearfix;

}

h1.styleguide,
h2.styleguide,
h3.styleguide,
h4.styleguide,
h5.styleguide {
  font-size: 16px;
  margin: 2em 0 1em;
}

.styleguide-table + table {
  width: 100%;
  margin-bottom: 2em;
  border-top: solid 1px #ccc;
  border-left: solid 1px #ccc;
  vertical-align: middle;
  th {
    padding: 4px;
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
  }
  td {
    padding: 4px;
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
  }
}

pre {
  border: 1px solid #aaa;
  border-radius: 0;
  background-color: whitesmoke;
  overflow-x: scroll;
  padding: 30px;

}

code {
  white-space: pre !important;
}

div.codeExample {
  // margin-bottom: 60px;
}

div.jsExample {
  border-top: 0;
}

div.jsExample:before {
  top: -11px;
  left: -11px;
  width: 80px;
  background-color: #fff;
  content: "JS Example";
}

div.exampleOutput {
  margin-bottom: 10px;
}

div.exampleOutput + div.codeBlock {
  border-width: 1px 0 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

div.codeBlock {
  margin: .5em 0 50px;
  overflow: scroll;
  @include media-sp-max {
    display: none;
  }
}

.codeExample div.codeBlock {
  margin-bottom: 0;
}

.codeExample + .styleguide,
.codeExample + .sg-text {
  margin-top: 60px;
}

.docSwatch {
  min-height: 218.21px;
  margin-bottom: 5px;
  padding: 10px 0 0 10px;
  border: 1px solid #ccc;
  font-size: 12px;
}

.codeExample .line > div:after {
  display: block;
  min-height: 40px;
  border-radius: $border-radius-pc;
  background-color: #eee;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  content: attr(class);
}

.sg-colors {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  list-style: none;
  li {
    flex: auto;
    min-width: 5em;
    max-width: 14em;
    margin: 0 .5em .5em 0;
    box-shadow: .5px .9px 4px rgba(0, 0, 0, .1);
    padding-bottom: 5px;

    border-radius: $border-radius-pc;
    &:before {
      display: none !important;
    }
  }
}

.sg-swatch {
  display: block;
  height: 4em;
  margin-bottom: 5px;
  border-radius: $border-radius-pc $border-radius-pc 0 0;
  border-bottom: solid 1px whitesmoke;
}

.sg-label,
.sg-label-sub {
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}

.sg-label {
  font-size: 12px;
  font-weight: bold;
}

