@charset "utf-8";

/* Tools
   ========================================================================== */


//必須･任意
.e-form-req,
.e-form-any {
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 8px;
  border-radius: $border-radius-pc;
  background-color: $brand-orange;
  color: #fff;
  font-size: .75rem;
  display: inline-block;
  font-weight: normal;

}

.e-form-any {
  background-color: $brand-light-green;
}

//フォーム内ツール
.e-form-text {
  margin-bottom: .5em;
  font-size: .875em;
}

.e-form-small-text {
  font-size: .75em;
  margin-bottom: .5em;
  font-weight: normal;
}

.e-form-message-box {
  font-size: .875em;
  padding-right: 1.5%;
  padding-left: 1.5%;
  box-sizing: border-box;
  clear: both;
  display: inline-block;
  width: 100%;
}

.e-form-error-text,
.e-form-valid-text,
.e-form-warning-text {
  margin-bottom: .5em;
}

.e-form-error-text {
  color: $brand-red;
}

.e-form-valid-text {
  color: #8cc152;
}

.e-form-warning-text {
  color: $brand-orange;
}


$form-table-border: 1px solid $border-color;
$form-table-bg: $bg-color;
$form-table-padding-vertical: 1.5em;
$form-table-padding-horizon: 1.2em;
$form-table-small-fsize: .7em;

.e-form-table {
  width: 100%;
  border-top: $form-table-border;
  border-collapse: collapse;

  @include mb-medium;

  tr,
  td,
  th {
    text-align: left;
    vertical-align: top;
    word-break: break-all;
  }

  th {
    padding: $form-table-padding-vertical $form-table-padding-horizon $form-table-padding-vertical 0;
    border-bottom: $form-table-border;
    vertical-align: top;
    width: 26%;

    @include media-sp-max {
      border-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      display: block;
      width: auto;
      padding-top: $form-table-padding-vertical * .8;
      padding-bottom: 0;
    }
  }

  td {
    padding: $form-table-padding-vertical $form-table-padding-horizon $form-table-padding-vertical 0;
    border-bottom: $form-table-border;

    @include media-sp-max {
      padding-left: 0;
      padding-right: 0;
      display: block;
      width: auto;

      padding-top: ($form-table-padding-vertical * .8) - .5em;
      padding-bottom: $form-table-padding-vertical * .8;
    }

  }

  //テーブルの中の最後の要素のマージンを消す
  th,
  td {
    *:last-child {
      //  margin-bottom: 0;
    }

  }

}

//btn
$btn-padding-vertical: .9rem;
$btn-padding-horizon: 3.8rem;
$btn-line-height: 1.2;
$btn-border: 0;
$btn-border-radius: 2px;

.e-form-btn,
.e-form-btn {
  font-size: 1.125rem;
  font-weight: bold;
  text-decoration: none !important;
  color: $text-color !important;
  padding: $btn-padding-vertical $btn-padding-horizon;
  min-width: 64px;
  max-width: 100%;
  box-sizing: border-box;
  transition: all $animation;
  border-radius: $btn-border-radius;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  appearance: none;
  user-select: none;
  outline: none;
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: $btn-line-height;
  background-color: #fff;
  border: $btn-border;

  @include media-sp-max {
    padding: ($btn-padding-vertical) ($btn-padding-horizon * .8);
  }

  @include elevation(2);
  @include elevation-transition;

  &:hover,
  &:focus,
  &.is-hover {
    text-decoration: none;
    @include elevation(4);
  }

  &:active {
    text-decoration: none;
    @include elevation(8);
  }

  &:disabled,
  &.is-disabled {
    cursor: not-allowed;
    background-color: #eee;
    border: 1px solid $border-color;
    color: #666 !important;
    @include elevation(0);
  }

  .material-icons {
    font-size: 1em;
  }
}

.e-form-btn--submit {
  background-color: #5451aa;
  color: #fff !important;

}

.e-form-btn-group {
  margin-right: -$margin-xs-pc;
  margin-left: -$margin-xs-pc;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  @include mb-small;

  @include media-sp-max {
    &__item-order1--sp {
      order: 1;
    }
    &__item-order2--sp {
      order: 2;
    }
  }

  .e-form-btn {
    margin-bottom: $margin-small-pc;
    margin-right: $margin-xs-pc;
    margin-left: $margin-xs-pc;

    @include media-sp-max {
      width: 100%;
    }
  }
}
