@charset "utf-8";

/* ==========================================================================
   #FLEX
   ========================================================================== */

/**
 * フレックスボックスのスタイルです
 *
 *
 *
 */

/* flex
   ========================================================================== */

/*doc
---
title: flex
name: flex
category: grid-12column
---

```html_example

<div class="is-flex-boxes">
 <div class="is-flex-box is-flex-box--large"><div class="dummy-box">.is-flex-box--large</div></div>
 <div class="is-flex-box is-flex-box--medium"><div class="dummy-box">.is-flex-box--medium</div></div>
 <div class="is-flex-box is-flex-box--medium"><div class="dummy-box">.is-flex-box--medium</div></div>
 <div class="is-flex-box is-flex-box--small"><div class="dummy-box">.is-flex-box--small</div></div>
 <div class="is-flex-box">  <div class="dummy-box">.is-flex-box</div></div>
 <div class="is-flex-box">  <div class="dummy-box">.is-flex-box</div></div>
</div>


```
*/

//必要な値をgrid-12columnから持ってくる

.is-flex-boxes {
  @include clearfix;
  align-items: stretch;
  clear: both;
  display: flex;
  flex-wrap: wrap;
  //justify-content: center;
  margin-right: -1%;
  margin-left: -1%;
  @include media-sp-max {
    margin-right: -1.7142857143%;
    margin-left: -1.7142857143%;
  }
}

.is-flex-box {
  align-self: stretch;
  display: block;
  flex: 2 2 15em;
  box-sizing: border-box;

  margin-right: 1%;
  margin-left: 1%;

  > *:last-child {
    margin-bottom: 0 !important;
  }

  //margin-bottom: 1.92%;
  margin-bottom: 16px;

  @include media-sp-max {
    margin-right: 1.7142857143%;
    margin-left: 1.7142857143%;
    margin-bottom: $width-gap-sp;
  }
}

.is-flex-box--small {
  flex: 0 0 14.6666666667%;
  @include media-sp-max {
    flex: 0 0 46.5714285714%;
  }
}

.is-flex-box--medium {
  flex: 0 0 23%;

  @include media-sp-max {
    flex: 0 0 46.5714285714%;
  }
}

.is-flex-box--large {
  flex: 1 1 31.3333333333%;
  @include media-sp-max {
    flex: 1 1 96.5714285714%;
  }
}


