@charset "utf-8";


/* イベント

   ========================================================================== */


/*doc
---
title: event-calender
name: event-calender
category: snippets-other
---

```html_example

        <div class="_snippets-event-calender">
          <div class="_head">
            <h2 class="_h-title">2018年1月</h2>
            <div class="_h-pager">
              <a href="" class="_h-prev"><i class="material-icons">navigate_before</i></a>
              <a href="" class="_h-next"><i class="material-icons">navigate_next</i></a>
            </div>
          </div>
          <div class="_body">
            <table class="_table">
              <tr>
                <th class="_red"><span>1</span><span>月</span></th>
                <td>
                  <div class="_item">
                    <p class="_i-label"><span class="e-label">入学希望者</span> <span class="e-label">在学生</span></p>
                    <p class="_i-title"><a href="">ラベルが複数ある場合はこのようになります</a></p>
                    <p class="_i-date">2018.01.03 - 2018.01.09（土日祝は除く）</p>
                  </div>
                </td>
              </tr>
              <tr class="_today">
                <th class="_blue"><span>1</span><span>月</span></th>
                <td>
                  <div class="_item">
                    <p class="_i-label"><span class="e-label">入学希望者</span> <span class="e-label">在学生</span></p>
                    <p class="_i-title"><a href="">ラベルが複数ある場合はこのようになります</a></p>
                    <p class="_i-date">2018.01.03 - 2018.01.09（土日祝は除く）</p>
                  </div>
                  <div class="_item">
                    <p class="_i-label"><span class="e-label">入学希望者</span> <span class="e-label">在学生</span></p>
                    <p class="_i-title"><a href="">ラベルが複数ある場合はこのようになります</a></p>
                    <p class="_i-date">2018.01.03 - 2018.01.09（土日祝は除く）</p>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="_red"><span>1</span><span>月</span></th>
                <td></td>
              </tr>
            </table>
          </div>
        </div>

```
*/


._snippets-event-calender {
  @include mb-small;

  ._head {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-bottom: 47px;

    @include media-sp-max {
      justify-content: space-between;
      margin-bottom: 18px;
    }
  }

  ._h-title {
    @include fsize(22px, false, 18px);
    margin-right: 47px;
    font-weight: bold;
  }

  ._h-pager {}

  ._h-prev,
  ._h-next {
    margin-left: 5px;
    font-weight: bold;
    text-decoration: none;
    color: $text-color;
    width: 36px;
    height:36px;

    i {
      line-height: 34px;
      font-size: 24px;
    }

    box-sizing: border-box;
    transition: all $animation;
    border-radius: $btn-border-radius;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    white-space: nowrap;
    appearance: none;
    user-select: none;
    outline: none;
    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    background-color: #fff;

    @include elevation(2);
    @include elevation-transition;

    &:hover,
    &:focus,
    &.is-hover {
      text-decoration: none;
      @include elevation(4);
    }

    &:active {
      text-decoration: none;
      @include elevation(8);
    }

  }

  ._body {}
  ._table {
    border-top: 1px solid $border-color;
  }

  tr {  }

  th {
    padding: 16px;
    padding-right: 0;
    vertical-align: top;
    width: 16%;
    font-weight: normal;
    border-bottom: 1px solid $border-color;

    @include media-sp-max {
    padding: 13px;
    padding-right: 0;
     width: 20%;
    }

    span {
      min-width: 2em;
      margin-right: 10px;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }

      @include media-sp-max {
        min-width: 1.6em;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }

    }
  }

  td {
    padding-top: 16px;
    padding-right: 16px;
    border-bottom: 1px solid $border-color;


    @include media-sp-max {
      padding-top: 13px;
      padding-right: 13px;
    }
  }

  ._today th {
    font-weight: bold;
    background-color: #f7f7f7;
  }

  ._today td {
    background-color: #f7f7f7;
  }

  ._red {
    color: #ff1919;
  }

  ._blue {
    color: #1e6cca;
  }


  ._item {
    @include mb-small
  }

  ._i-label {
    @include fsize(12px);
    margin-bottom: 4px;

  }

  ._i-title {}

  ._i-date {
    margin: .1em 1em .1em 0;
    @include fsize(12px);
    color: $text-color-sub;
    min-width: 6em;
  }

}


