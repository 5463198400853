@charset "utf-8";
/* @ Tool Setting
 * ------------------------------------------------------------ */

/*doc
---
title: tools
name: helpers2
category: helpers
---


`--sm`, `--md`, `--lg`

<div class="styleguide-table"></div>
 Class        |  Description
 -------------| ----------
 `is-gapless`          |
 `is-mb-xs`        |
 `is-mb-small`         |
 `is-mb-medium`       |
 `is-mb-large`        |
 `is-mb-xl`        |
 `is-mt0`             |
 `is-mb0`             |
 `is-w100p`         |
 `is-left`           |  左揃え
 `is-right`          |  右揃え
 `is-center`         |  中央揃え
 `is-ib`             |  表示
 `is-block`          |  表示
`is-list-style-type--circle`          |
`is-list-style-type--disc`          |
`is-list-style-type--square`          |
`is-list-style-type--lower-alpha`          |
`is-list-style-type--lower-greek`          |
`is-list-style-type--lower-roman`          |
`is-list-style-type--upper-alpha`          |
`is-list-style-type--upper-roman`          |
*/

//vue用
[v-cloak] {
  display: none;
}

//swipe js初期非表示
.swiper-container {
  display: none;

  &.swiper-container-horizontal {
    display: block;
  }
}


/* @ base layout gap
 * ------------------------------------------------------------ */

.is-gapless {
  margin-right: -$width-gap-sp;
  margin-left: -$width-gap-sp;
}

@include media-lg-min {

  .is-gapless--lg {
    margin-right: -$width-gap-sp;
    margin-left: -$width-gap-sp;
  }
}

@include media-tab-max {

  .is-gapless--md {
    margin-right: -$width-gap-tab;
    margin-left: -$width-gap-tab;
  }
}

@include media-sp-max {

  .is-gapless--sm {
    margin-right: -$width-gap-sp;
    margin-left: -$width-gap-sp;
  }
}

/* @ base margin
 * ------------------------------------------------------------ */

@mixin base-margin($name, $val-pc, $val-tab, $val-sp) {

  .#{$name} {

    margin-bottom: $val-pc !important;

    @include media-tab-max {
      margin-bottom: $val-tab !important;
    }

    @include media-sp-max {
      margin-bottom: $val-sp !important;
    }
  }
}

@include base-margin (is-mb-xs, $margin-xs-pc, $margin-xs-tab, $margin-xs-sp);
@include base-margin (is-mb-small, $margin-small-pc, $margin-small-tab, $margin-small-sp);
@include base-margin (is-mb-medium, $margin-medium-pc, $margin-medium-tab, $margin-medium-sp);
@include base-margin (is-mb-large, $margin-large-pc, $margin-large-tab, $margin-large-sp);

.is-mt0 {
  margin-top: 0 !important;
}

.is-mb0 {
  margin-bottom: 0 !important;
}

.is-w100p {
  width: 100% !important;
}

@include media-lg-min {
  .is-mt0--lg {
    margin-top: 0 !important;
  }
  .is-mb0--lg {
    margin-bottom: 0 !important;
  }
  .is-w100p--lg {
    width: 100% !important;
  }

}

@include media-tab-max {
  .is-mt0--md {
    margin-top: 0 !important;
  }
  .is-mb0--md {
    margin-bottom: 0 !important;
  }
  .is-w100p--md {
    width: 100% !important;
  }

}

@include media-sp-max {
  .is-mt0--sm {
    margin-top: 0 !important;
  }
  .is-mb0--sm {
    margin-bottom: 0 !important;
  }
  .is-w100p--sm {
    width: 100% !important;
  }

}

/* @ 装飾
 * ------------------------------------------------------------ */

//リストスタイル
.is-list-style-type--circle {
  list-style-type: circle!important;
}
.is-list-style-type--disc {
  list-style-type: disc !important;
}
.is-list-style-type--square {
  list-style-type: square !important;
}
.is-list-style-type--lower-alpha {
  list-style-type: lower-alpha !important;
}
.is-list-style-type--lower-greek {
   list-style-type: lower-greek !important;
}
.is-list-style-type--lower-roman {
  list-style-type: lower-roman !important;
}
.is-list-style-type--upper-alpha {
  list-style-type: upper-alpha !important;
}
.is-list-style-type--upper-roman {
  list-style-type: upper-roman !important;
}


.is-center {
  text-align: center;
  justify-content: center;
}

.is-left {
  text-align: left;
  justify-content: flex-start;
}

.is-right {
  text-align: right;
  justify-content: flex-end;
}


.is-justify {
  text-align: justify;
  justify-content: space-between;
}


.is-ib {
  display: inline-block;
}

.is-block {
  display: block;
}


@include media-lg-min {
  .is-center--lg {
    text-align: center;
    justify-content: center;
  }

  .is-left--lg {
    text-align: left;
    justify-content: flex-start;
  }

  .is-right--lg {
    text-align: right;
    justify-content: flex-end;
  }

  .is-justify--lg {
    text-align: justify;
    justify-content: space-between;
  }


  .is-ib--lg {
    display: inline-block;
  }

  .is-block--lg {
    display: block;
  }


}

@include media-tab-max {

  .is-center--md {
    text-align: center;
    justify-content: center;
  }

  .is-left--md {
    text-align: left;
    justify-content: flex-start;
  }

  .is-right--md {
    text-align: right;
    justify-content: flex-end;
  }

  .is-justify--md {
    text-align: justify;
    justify-content: space-between;
  }

  .is-ib--md {
    display: inline-block;
  }

  .is-block--md {
    display: block;
  }

}

@include media-sp-max {
  .is-center--sm {
    text-align: center;
    justify-content: center;
  }

  .is-left--sm {
    text-align: left;
    justify-content: flex-start;
  }

  .is-right--sm {
    text-align: right;
    justify-content: flex-end;
  }

  .is-justify--sm {
    text-align: justify;
    justify-content: space-between;
  }

  .is-ib--sm {
    display: inline-block;
  }

  .is-block--sm {
    display: block;
  }
}


/*doc
---
title: 表示･非表示
name: helpers
category: helpers
---

`--sm`, `--md`, `--lg`

<div class="styleguide-table"></div>
 Class                 |  Description
 ----------------------| ----------
 `is-prompt`            |  古いIEへ警告など
 `is-visible`           |  表示
 `is-hidden`            |  非表示


*/

//古いIEへ警告
.is-prompt {
  margin: 0 auto;
  padding: 10px;
  background-color: #aaa;
  color: $brand-white;
  text-align: center;

  a {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}

// 表示,非表示
.is-visible {
  display: block;
}

.is-hidden {
  display: none;
}

.is-visible--sm,
.is-visible--md,
.is-visible--lg {
  display: none;
}

@include media-lg-min {
  .is-visible--lg {
    display: block;
  }
}

@include media-tab-max {
  .is-visible--md {
    display: block;
  }
}

@include media-sp-max {
  .is-visible--sm {
    display: block;
  }
}

@include media-lg-min {
  .is-hidden--lg {
    display: none;
  }
}

@include media-tab-max {
  .is-hidden--md {
    display: none;
  }
}

@include media-sp-max {
  .is-hidden--sm {
    display: none;
  }
}



