@charset "utf-8";

/* @ Layout
 * ------------------------------------------------------------ */

/* Layout
   ========================================================================== */
/*doc
---
title: Layout
name: form-layout
category: form
---

【フォームの基本形】<br>
`.e-form-table`         = 大枠<br>
`.e-form-unit`          = 1かたまり<br>
`.e-form-head`          = 見だし<br>
`.e-form-field`         = フィールド<br>
※`.e-form-field`の中は`.e-form-col-assy > .e-form-col12` 等で囲みその中に`inputタグ`を記述<br><br>


【レイアウト】<br>
`.e-form-col-assy`      = グリッドレイアウト枠<br>
`.e-form-col[0_5 - 12]` = グリッドレイアウト スマホ時は続けて `.e-form-col12--sm`等を記述<br><br>



【ヘルパー】<br>
`.e-form-control`       = input, textarea, selectなどのinput要素<br>
`.e-form-text-dash`     = .e-form-field内の「 - 」を囲むタグ`.e-form-col0_5`の中に記述<br>
`.e-form-inframe`       = .e-form-field内インラインフレーム<br>
`[role="tooltip"]`    = 入力タイプ説明をツールチップで紐付ける<br>
`.e-form-req`    = 必須<br>
`.e-form-any`    = 任意<br>
`.e-form-text`    = フォームテキスト<br>
`.e-form-small-text`    = フォームスモールテキスト<br>


*/

.e-form-unit {
  opacity: 1;
}

.e-form-head {
  opacity: 1;
}

.e-form-field {
  opacity: 1;

}

.e-form-col-assy {
  //margin-top: -.8rem;
  margin-right: -1.5%;
  margin-left: -1.5%;
  @include clearfix;
  display: block;
}



.e-form-col0_5,
.e-form-col1,
.e-form-col2,
.e-form-col3,
.e-form-col4,
.e-form-col5,
.e-form-col6,
.e-form-col7,
.e-form-col8,
.e-form-col9,
.e-form-col10,
.e-form-col11,
.e-form-col12 {
  margin-bottom: .5em;
  padding-right: 1.5%;
  padding-left: 1.5%;
  float: left;
  box-sizing: border-box;
  min-height: 38px;
}

.e-form-text-dash {
  line-height: 38px;
  text-align: center;
  display: block;
}

$col-width: 8.333333333;

.e-form-col0_5 {
  width: $col-width * .5%;
}

.e-form-col1 {
  width: $col-width * 1%;
}

.e-form-col2 {
  width: $col-width * 2%;
}

.e-form-col3 {
  width: $col-width * 3%;
}

.e-form-col4 {
  width: $col-width * 4%;
}

.e-form-col5 {
  width: $col-width * 5%;
}

.e-form-col6 {
  width: $col-width * 6%;
}

.e-form-col7 {
  width: $col-width * 7%;
}

.e-form-col8 {
  width: $col-width * 8%;
}

.e-form-col9 {
  width: $col-width * 9%;
}

.e-form-col10 {
  width: $col-width * 10%;
}

.e-form-col11 {
  width: $col-width * 11%;
}

.e-form-col12 {
  width: $col-width * 12%;
  // float: none;
  // display: inline-block;
}

@include media-sp-max {

  .e-form-col0_5--sm {
    width: $col-width * .5%;
  }

  .e-form-col1--sm {
    width: $col-width * 1%;
  }

  .e-form-col2--sm {
    width: $col-width * 2%;
  }

  .e-form-col3--sm {
    width: $col-width * 3%;
  }

  .e-form-col4--sm {
    width: $col-width * 4%;
  }

  .e-form-col5--sm {
    width: $col-width * 5%;
  }

  .e-form-col6--sm {
    width: $col-width * 6%;
  }

  .e-form-col7--sm {
    width: $col-width * 7%;
  }

  .e-form-col8--sm {
    width: $col-width * 8%;
  }

  .e-form-col9--sm {
    width: $col-width * 9%;
  }

  .e-form-col10--sm {
    width: $col-width * 10%;
  }

  .e-form-col11--sm {
    width: $col-width * 11%;
  }

  .e-form-col12--sm {
    width: 100%;
    //float: none;
    // display: inline-block;
  }
}
