@charset "utf-8";

/* ==========================================================================
   #Accordion
   ========================================================================== */

/*doc
---
title: accordion
name: accordion
category: accordion
---

```html_example

<div class="c-accordion" data-accordion>
 <button type="button" class="c-accordion__title" aria-controls="accordion1" aria-expanded="true" aria-label="開く" data-toggle-accordion>情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った - NONE</button>
 <div id="accordion1" class="c-accordion__body" aria-hidden="true" style="display:none;" data-body-accordion>
  <p>情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。</p>
 </div>
</div>

<div class="c-accordion is-active" data-accordion data-device-accordion="all">
 <button type="button" class="c-accordion__title" aria-controls="accordion2" aria-expanded="false" aria-label="閉じる" data-toggle-accordion>情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った - ALL</button>
 <div id="accordion2" class="c-accordion__body" aria-hidden="false" data-body-accordion>
  <p>情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。</p>
 </div>
</div>

<div class="c-accordion" data-accordion data-device-accordion="pc">
 <button type="button" class="c-accordion__title" aria-controls="accordion3" aria-expanded="true" aria-label="開く" data-toggle-accordion>情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った - PC</button>
 <div id="accordion3" class="c-accordion__body" aria-hidden="true" style="display:none;" data-body-accordion>
  <p>情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。</p>
 </div>
</div>

<div class="c-accordion" data-accordion data-device-accordion="tab">
 <button type="button" class="c-accordion__title" aria-controls="accordion3" aria-expanded="true" aria-label="開く" data-toggle-accordion>情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った - TAB</button>
 <div id="accordion3" class="c-accordion__body" aria-hidden="true" style="display:none;" data-body-accordion>
  <p>情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。</p>
 </div>
</div>

<div class="c-accordion" data-accordion data-device-accordion="sp">
 <button type="button" class="c-accordion__title" aria-controls="accordion3" aria-expanded="true" aria-label="開く" data-toggle-accordion>情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った - SP</button>
 <div id="accordion3" class="c-accordion__body" aria-hidden="true" style="display:none;" data-body-accordion>
  <p>情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。</p>
 </div>
</div>





```

<div class="c-accordion" data-accordion data-device-accordion="all">
  <button type="button" class="c-accordion__title" aria-expanded="true" aria-label="開く" data-toggle-accordion>Javascript</button>
    <div class="c-accordion__body" aria-hidden="true" style="display:none;" data-body-accordion>
  <ul class="list list--disc">
    <li>data-accordion class="is-active"</li>
    <li>data-toggle-accordion</li>
    <li>data-body-accordion  style="display:none;"</li>
    <li>data-device-accordion = `all`, `pc`, `tab`, `sp`</li>
  </ul>
    </div>
</div>


<div class="c-accordion" data-accordion data-device-accordion="all">
  <button type="button" class="c-accordion__title" aria-expanded="true" aria-label="開く" data-toggle-accordion>Accessibility</button>
    <div class="c-accordion__body" aria-hidden="true" style="display:none;" data-body-accordion>
  <ul class="list list--disc">
    <li>表示領域の操作はマウスとキーボードの両方で使用可能です。</li>
    <li>ボタン領域の属性は、aria-expanded = `true`または`false`を設定します（必須）。</li>
    <li>ボタン領域の属性は、aria-label = `開く`または`閉じる`を設定します（必須）。</li>
    <li>ボタン領域にaria-controls = `id名`、コンテンツ領域に該当する`id名`を設定し、表示領域の関連付けを設定します（任意）。</li>
    <li>コンテンツ領域の属性は、対応するボタン領域の属性に応じて、aria-hidden= `true`または`false`を設定します（必須）。</li>
  </ul>
    </div>
</div>




*/

.c-accordion {
  border-bottom: 0;
  background: $brand-white;

  @include mb-small;

  + .c-accordion {

    margin-top: -$margin-small-pc;
    @include media-tab-max {
      margin-top: -$margin-small-tab;
    }
    @include media-sp-max {
      margin-top: -$margin-small-sp;
    }

    .c-accordion__title {
      border-top: 0;
    }
  }


  &__title {
    display: block;
    border-radius: 0;
    appearance: none;
    user-select: none;
    width: 100%;
    text-align: left;
    background-color: transparent;
    font-weight: bold;
    padding: 1rem 25px .8rem 20px;
    border: 1px solid $border-color;
    cursor: pointer;
    transition: $animation;
    line-height: $line-height-pc;

    &:hover,
    &:focus {
      background-color: $bg-color;
    }
  }

  &__title,
  &-title-icon {
    position: relative;
    &:after {
      color: $brand-primary;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
      height: 1em;
      @include material-icons;
      vertical-align: middle;
      content: "expand_more";
      white-space: nowrap;
      line-height: 1em;

      @include fsize(false, false, 20px);

      @include media-sp-max {
        right: 5px;
      }

    }

    &[aria-expanded="false"]:after {
      content: "expand_less";
    }

  }

  &__body {
    border: 1px solid $border-color;
    border-top: 0;
    padding: 25px 20px;

  }

}
