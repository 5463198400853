@charset "utf-8";

/* ==========================================================================
   #BLOCKQUOTE
   ========================================================================== */

/* 引用
   ========================================================================== */

/*doc
---
title: Code
name: code
category: code
---


```html_example
<div><code>code</code></div>
<div><kbd>kbd</kbd></div>
<div><samp>samp</samp></div>

```

.pre > code

```html_example

<pre class="e-pre">
<code>
&lt;div class="pager"&gt;
    &lt;ul class="pager__inner"&gt;
      &lt;li class="pager__prev"&gt;&lt;a href="javascript:void(0)" class="btn is-disabled"&gt;前のエントリーを読む&lt;/a&gt;&lt;/li&gt;
      &lt;li class="pager__next"&gt;&lt;a href="javascript:void(0)" class="btn"&gt;次のエントリーを読む &lt;/a&gt;&lt;/li&gt;
    &lt;/ul&gt;
  &lt;/div&gt;
&lt;code&gt;
&lt;/code&gt;
</code></pre>

```



.e-pre.e-pre--scrollable > code


```html_example

<pre class="e-pre e-pre--scrollable">
<code>
&lt;div class="pager"&gt;
    &lt;ul class="pager__inner"&gt;
      &lt;li class="pager__prev"&gt;&lt;a href="javascript:void(0)" class="btn is-disabled"&gt;前のエントリーを読む&lt;/a&gt;&lt;/li&gt;
      &lt;li class="pager__next"&gt;&lt;a href="javascript:void(0)" class="btn"&gt;次のエントリーを読む &lt;/a&gt;&lt;/li&gt;
    &lt;/ul&gt;
  &lt;/div&gt;
&lt;code&gt;
&lt;/code&gt;
&lt;div class="pager"&gt;
    &lt;ul class="pager__inner"&gt;
      &lt;li class="pager__prev"&gt;&lt;a href="javascript:void(0)" class="btn is-disabled"&gt;前のエントリーを読む&lt;/a&gt;&lt;/li&gt;
      &lt;li class="pager__next"&gt;&lt;a href="javascript:void(0)" class="btn"&gt;次のエントリーを読む &lt;/a&gt;&lt;/li&gt;
    &lt;/ul&gt;
  &lt;/div&gt;
&lt;code&gt;
&lt;/code&gt;
&lt;div class="pager"&gt;
    &lt;ul class="pager__inner"&gt;
      &lt;li class="pager__prev"&gt;&lt;a href="javascript:void(0)" class="btn is-disabled"&gt;前のエントリーを読む&lt;/a&gt;&lt;/li&gt;
      &lt;li class="pager__next"&gt;&lt;a href="javascript:void(0)" class="btn"&gt;次のエントリーを読む &lt;/a&gt;&lt;/li&gt;
    &lt;/ul&gt;
  &lt;/div&gt;
&lt;code&gt;
&lt;/code&gt;
</code></pre>


*/
/* @ Code Layout
 * ------------------------------------------------------------ */

code,
kbd,
pre,
samp {
  font-family: $font-family-monospace;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: $border-radius-pc;
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: $border-radius-pc / 2;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);

  kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold;
    box-shadow: none;
  }
}

pre,
.e-pre {
  display: block;
  padding: 10px;
  white-space: pre-wrap;
  @include fsize(14px);
  line-height: $line-height-pc;
  word-break: break-all;
  word-wrap: break-word;
  color: #aaa;
  background-color: $bg-color;
  border: 1px solid #ccc;
  border-radius: $border-radius-pc;

  margin: 0;

  @include mb-small;

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}

// Enable scrollable blocks of code
.e-pre--scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

//---------------------------------------------------

