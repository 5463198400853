@charset "utf-8";

/* @ icon
 * ------------------------------------------------------------ */

/*doc
---
title: アイコン
name: icon
category: icon
---

```html_example
<p><a href="" class="is-icon-target-blank">target="_blank"を指定している場合</a></p>
<p><a href="" class="is-icon-pdf">pdfへのリンクの場合</a></p>
<p><a href="" class="is-icon-doc">doc、docxへのリンクの場合</a></p>
<p><a href="" class="is-icon-ppt">ppt、pptxへのリンクの場合</a></p>
<p><a href="" class="is-icon-xls">xls、xlsxへのリンクの場合</a></p>
```

<a href="https://material.io/icons/" target="blank">https://material.io/icons/</a>

```html_example
<i class="material-icons">phone</i>
<i class="material-icons">fiber_new</i>
<i class="material-icons">mail</i>

<i class="material-icons">launch</i>
<i class="material-icons">picture_as_pdf</i>
<i class="material-icons">video_library</i>
<i class="material-icons">library_books</i>
<i class="material-icons">photo_library</i>

<i class="material-icons">priority_high</i>
<i class="material-icons">warning</i>
<i class="material-icons">check</i>

<i class="material-icons">add</i>
<i class="material-icons">remove</i>

<i class="material-icons">menu</i>
<i class="material-icons">close</i>

<i class="material-icons">expand_less</i>
<i class="material-icons">expand_more</i>

<i class="material-icons">navigate_before</i>
<i class="material-icons">navigate_next</i>

```
*/

.is-icon-target-blank,
.rich-editor [target="_blank"] {
  &:after {
    @include material-icons;
    vertical-align: middle;
    content: "open_in_new";
    color: $text-color-sub;
    margin-left: .3em;
    min-width: 1em;
    text-align: right;
  }
}




.is-icon-pdf,
.rich-editor a[href$=".pdf"],
.is-icon-doc,
.rich-editor a[href$=".doc"],
.rich-editor a[href$=".docx"],
.is-icon-ppt,
.rich-editor a[href$=".ppt"],
.rich-editor a[href$=".pptx"],
.is-icon-xls,
.rich-editor a[href$=".xls"],
.rich-editor a[href$=".xlsx"] {
  &:after {
    background: url("#{$img-path}/common/icon_pdf.png") right center no-repeat;
    background-size: 15.5px 15.5px;
    width: 15.5px;
    height: 15.5px;
    content: "";
    display: inline-block;
    margin-left: .3em;
    text-align: right;
  }
}


//noicon

//<p><a href="#dummy.ppt" class="noicon">.noicon自動リンクアイコンを非表示</a></p>
.noicon {
  &:after {
    display: none!important;
  }
}

//<p><span class="noicon"><a href="#dummy.ppt" >.noicon自動リンクアイコンを非表示</a></span></p>
.noicon a[target="_blank"],
.noicon a[href$=".pdf"],
.noicon a[href$=".doc"],
.noicon a[href$=".docx"],
.noicon a[href$=".ppt"],
.noicon a[href$=".pptx"],
.noicon a[href$=".xls"],
.noicon a[href$=".xlsx"] {
  &:after {
    display: none!important;
  }
}


.is-icon-pdf,
.rich-editor a[href$=".pdf"] {
  &:after {
    background-image: url("#{$img-path}/common/icon_pdf.png");
  }
}

.is-icon-doc,
.rich-editor a[href$=".doc"],
.rich-editor a[href$=".docx"] {
  &:after {
    background-image: url("#{$img-path}/common/icon_doc.png");
  }
}


.is-icon-ppt,
.rich-editor a[href$=".ppt"],
.rich-editor a[href$=".pptx"] {
  &:after {
    background-image: url("#{$img-path}/common/icon_ppt.png");
  }
}

.is-icon-xls,
.rich-editor a[href$=".xls"],
.rich-editor a[href$=".xlsx"] {
  &:after {
    background-image: url("#{$img-path}/common/icon_xls.png");
  }
}


.is-icon-x {
  background: url("#{$img-path}/common/x.svg") right center no-repeat;
  width: 24px;
  height: 24px;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.is-icon-x--white {
  background: url("#{$img-path}/common/x_white.svg") right center no-repeat;
}


.is-icon-youtube {
  background: url("#{$img-path}/common/youtube.svg") right center no-repeat;
  width: 24px;
  height: 24px;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.is-icon-youtube--white {
  background: url("#{$img-path}/common/youtube_white.svg") right center no-repeat;
}


.is-icon-badge {
  position: relative;

  &:before {
    width:4px;
    height:4px;
    border-radius: 100px;
    position: absolute;
    top: 2px;
    right: 0px;
    display: block;
    content: '';
    z-index: 2;
    background-color: #fff;
  }

  &:after {
    z-index: 1;
    width:8px;
    height:8px;
    border-radius: 100px;
    position: absolute;
    top: -2px;
    right: -4px;
    display: block;
    content: '';
    background-color: #ff0000;
    border: 2px solid #fff;
  }
}

.is-icon-new {

  //position: relative;
  color: #000;
  font-style: normal;
  display: inline;
  font-size: 8px;
  background-color: $brand-secondary;
  border-radius: 25px;
  padding: .2em .8em ;
  margin-right: .2em;
  margin-left: .2em;
  vertical-align: middle;

}