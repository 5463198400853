@charset "utf-8";

/* ==========================================================================
   #Tppesetting
   ========================================================================== */

/* 枠
   ========================================================================== */

.entry,
.rich-editor {
  word-break: break-all;
  @include clearfix;
  @include mb-medium;
}

/*doc
---
title: テキスト装飾
name: typesetting2
category: typesetting
---


<div class="styleguide-table"></div>
Class                                                                      | Description
-----------------------------------------------------------------------    | ------------
`e-text`           | <p class="e-text">情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。とかくに人の世は住みにくい。 </p> |
`e-text-catchcopy`           | <p class="e-text-catchcopy">情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。とかくに人の世は住みにくい。 </p> |
`is-text-point`               | <p class="e-text-point">ダミーテキストです。ご了承ください。</p>                      |
`is-text-arrow`               | <p><a href="#dummy" class="e-text-arrow">一覧</a></p>                       |
`is-text-link`                | <div> <p class="e-text">情に棹させば流される。<a href="https://google.com" class="is-text-link">智に働けば角が立つ。</a>どこへ越しても住みにくいと悟った時、詩が生れて、<a href="https://google.com" class="is-text-link">画が出来る</a>。とかくに人の世は住みにくい。<a href="" class="is-text-link">意地を通せば窮屈</a>だ... </p> </div>         |
`is-dark is-text-link `       | <div class="is-dark" style="background-color: #333;"> <p class="e-text">情に棹させば流される。<a href="https://google.com" class="is-text-link">智に働けば角が立つ。</a>どこへ越しても住みにくいと悟った時、詩が生れて、<a href="https://google.com" class="is-text-link">画が出来る</a>。とかくに人の世は住みにくい。<a href="" class="is-text-link">意地を通せば窮屈</a>だ... </p> </div>        |
`is-text-bold`           |  太字
`is-text-br`             |  改行
`is-text-strike`         |  打ち消し線
`is-text-strong`         |  強調
`is-text-stronger`         |  より強い強調

*/


.rich-editor a,
a.is-text-link {

  &:link {
    color: $brand-link;
    text-decoration: none;
    transition: $animation;
  }

  &:hover,
  &:focus,
  &:visited {
    transition: $animation;
    color: $brand-link-hover;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: $brand-link-visited;
  }

}

.is-dark {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  dt,
  dd,
  p,
  span {
    color: $brand-white;
  }

  a.is-text-link {
    color: $brand-link-dark;
  }

  a.is-text-link:hover,
  a.is-text-link:visited {
    color: $brand-link-dark-visited;
  }

  a.is-text-link:hover {
    color: $brand-link-dark-hover;
  }
}

.rich-editor p,
.e-text {
  @include mb-small;
}

//.rich-editor strong > strong,
.e-text-catchcopy {
  font-weight: bold;
  @include fsize(16px, false, 16px);
  @include mb-small;
}



.is-text-sub {
  color: $text-color-sub;
  font-size: .8rem;
}

.is-text-week {
  color: $text-color-week;
  font-size: .8rem;
}

.is-text-arrow {
  &:before {
    color: $brand-primary;
    @include material-icons;
    vertical-align: middle;
    content: "navigate_next";
    white-space: nowrap;
    font-size: 1.2rem;
  }

  &:hover:before {
    text-decoration: none;
  }
}


.is-text-point {
  display: inline;
  //background: linear-gradient(transparent 70%, $brand-yellow 70%, $brand-yellow 90%, transparent 90%);
  background: linear-gradient(transparent 50%, #ffffa6 50%, #ffffa6 100%);
  padding-bottom: .1em;
  padding-right: .5em;

  @include media-sp-max {
    padding-right: 0;
  }
}

.is-text-strong {
  color: $brand-orange;
  font-weight: bold;
}

.is-text-stronger {
  color: $brand-red;
  font-weight: bold;
  @include fsize(16px, false, 16px);
  @include mb-small;
}


.is-text-bold {
  font-weight: bold;
}

.is-text-strike {
  text-decoration: line-through;
}


// 改行
.is-text-br:after {
  white-space: pre;
  content: "\A";
}

@include media-lg-min {
  .is-text-br--lg:after {
    white-space: pre;
    content: "\A";
  }
}

@include media-tab-max {
  .is-text-br--md:after {
    white-space: pre;
    content: "\A";
  }
}

@include media-sp-max {
  .is-text-br--sm:after {
    white-space: pre;
    content: "\A";
  }
}
