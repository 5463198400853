@charset "utf-8";

/* ==========================================================================
   #swiper
   ========================================================================== */

/*doc
---
title: swiper-top
name: swiper
category: swiper
---


```html_example

<div class="swiper-top">
  <div id="js-swiper-top" class="swiper-container">
    <div class="swiper-wrapper">

      <a href="" class="swiper-slide" style="background-color: #6e8800; color: #fff;">
        <div class="swiper-slide__item1">
          <img src="/assets/img/top/main_visual.png" alt="">
        </div>
        <div class="swiper-slide__item2">
          <p class="swiper-slide__date">2017.11.02</p>
          <p class="swiper-slide__title">タイトル</p>
          <p class="swiper-slide__text">テキストテキストテキストテキストテキストテキスト</p>
        </div>
     </a>
      <a href="" class="swiper-slide" style="background-color: #805d29; color: #fff;">
        <div class="swiper-slide__item1">
          <img src="/assets/img/top/main_visual2.png" alt="">
        </div>
        <div class="swiper-slide__item2">
          <p class="swiper-slide__date">2017.11.02</p>
          <p class="swiper-slide__title">タイトル</p>
          <p class="swiper-slide__text">テキストテキストテキストテキストテキストテキスト</p>
         </div>
      </a>

    </div>
    <div class="swiper-controls">
      <div class="swiper-pagination"></div>
      <div class="swiper-btn">
        <button class="swiper-stop"><i class="material-icons">pause</i></button>
        <button class="swiper-start on"><i class="material-icons">play_arrow</i></button>
      </div>
    </div>
  </div>
</div>

<script src="/assets/js/vendor/jquery.js"></script>
<script src="/assets/js/vendor/swiper.jquery.js"></script>
<script>

var swiperMain = new Swiper('#js-swiper-top', {
    pagination: '.swiper-top .swiper-pagination',
    paginationClickable: true,
    centeredSlides: false,
    loop: true,
    loopAdditionalSlides: 1,
    spaceBetween: 0,
    slidesPerView: 1,
    autoplay: 5000,
    autoHeight: true,
    effect: 'fade',
    breakpoints: {
      599: {
        onInit() {
          $(window).on('load resize', function () {
            mainVisualHeight();
          });
          function mainVisualHeight() {
            var windowH = $(window).height(),
                headerH = $('.l-header').height();

            $('#js-swiper-top .swiper-slide').each(function( index ) {
              $('.swiper-slide__item1 img', this).height(windowH - headerH - $('.swiper-slide__item2', this).outerHeight());
            });
          }
        }
      }
    },
    onInit() {
      let start = $('.swiper-top .swiper-start');
      let stop = $('.swiper-top .swiper-stop');
      start.on('click', function() {
        if (!start.hasClass('on')) {
          swiperMain.startAutoplay();
          start.addClass('on');
          stop.removeClass('on');
        }
      });
      stop.on('click', function() {
        if (!stop.hasClass('on')) {
          swiperMain.stopAutoplay();
          stop.addClass('on');
          start.removeClass('on');
        }
      });
    }
  });


</script>

```
*/

.swiper-top {
  @include mb-small;

  a:hover {
    text-decoration: none;
  }

  @include elevation(2);
  @include elevation-transition;

  &:hover,
  &:focus {
    @include elevation(4);
  }

  &:active {
    @include elevation(8);
  }

  .swiper-slide {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    background: url("#{$img-path}/top/main_visual-bg.png") 60% center no-repeat;
    background-color: #6e8800;
    color: #fff;
    margin-bottom: 50px;
    @include elevation(1);
    @include media-sp-max {

      display: block;
      background-image: none;
      height: calc(100vh - 53px);
    }
  }




  .swiper-slide__item1 {
    width: 50%;
    @include media-sp-max {
      width: auto;
    }

    img {
      object-fit: cover;
    }
  }
  .swiper-slide__item2 {
    width: calc(50% - 4rem);
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding: 2rem;
    padding-bottom: 46px;

    @include media-tab-max {
      width: calc(50% - 32px);
      padding-top: 16px;
      padding-right: 16px;
      padding-left: 16px;
    }
    @include media-sp-max {
      width: auto;
      position: relative;

      background: url("#{$img-path}/top/main_visual-bg.png") -44px center no-repeat;
      background-size: 209px 246px;
    }
  }

  .swiper-slide__date {
    @include fsize(12px);
    margin-bottom: 8px;
    @include media-sp-max {
      color: $text-color;
      background: #fff;
      padding: .5em;
      line-height: 1;
      display: inline-block;
      position: absolute;
      top: -2em;
      left: 0;
    }
  }

  .swiper-slide__title {
    @include fsize(24px, false, 16px);
    margin-bottom: 8px;
    font-weight: bold;
  }
  .swiper-slide__text {
    font-weight: bold;
    @include fsize(16px, false, 13px);
  }

  .swiper-container {
    position: relative;
  }

  .swiper-controls {
    background-color: rgba(0, 0, 0, .2);
    box-sizing: border-box;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 100;
    height: 46px;
    width: 50%;

    @include media-sp-max {
      width: 100%;
      height: 36px;
    }
  }

  .swiper-btn {
    position: absolute;
    right: 10px;
    top: 4px;
    margin: auto;
    z-index:999;
    @include media-sp-max {
      top: 0;
    }
  }
  .swiper-start,
  .swiper-stop {
    padding: 9px 3px 7px;
    max-width: 100%;
    margin-left: 1rem;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    appearance: none;
    user-select: none;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    opacity: .3;
    i {
      font-size: 20px;
      @include fsize(20px);
    }
  }

  .swiper-start.on,
  .swiper-stop.on {
    opacity: 1;
  }

  .swiper-pagination-bullets {
    position: static;
    text-align: left;
    margin-top: 8px;
    padding-right: 70px;

    @include media-sp-max {
      margin-top: 6px;
    }

    .swiper-pagination-bullet {
      background-color: #fff;
      width: 6px;
      height: 6px;
      margin: 0 10px;
      border: 1px solid transparent;
      opacity: .3;
    }

    .swiper-pagination-bullet-active {
      width: 10px;
      height: 10px;
      margin-bottom: -2px;
      border-color: #fff;
      background-color: transparent;
      opacity: 1;

    }
  }
}
