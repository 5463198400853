@charset "utf-8";

/* @  Font Reset
* ------------------------------------------------------------ */
@font-face {
  font-family: "MyYuGothicM";
  font-weight: normal;
  src: local("YuGothic-Medium"), /* Postscript name */
  local("Yu Gothic Medium"), /* for Chrome */
  local("YuGothic-Regular"); /* Mediumがない場合 */
}

@font-face {
  font-family: "MyYuGothicM";
  font-weight: bold;
  src: local("YuGothic-Bold"), /* Postscript name */
  local("Yu Gothic"); /* for Chrome */
}

html {
  line-height: $line-height-pc;
  @include media-lg-min {
    font-size: $fsize-pc;
  }
  @include media-pc-min {
    font-size: $fsize-pc;
  }
  @include media-tab-max {
    font-size: $fsize-tab;
  }
  @include media-sp-max {
    font-size: $fsize-sp;
  }

  @include media-min {
    font-size: (100 / 375) * ($fsize-sp / (1 + $fsize-sp - $fsize-sp)) * 1vmin;
  }
}

body {
  font-family: $font-family-base;
  color: $text-color;
  word-wrap: break-word;
  overflow-wrap: break-word;
}


p,
li,
dt,
dd,
th,
td,
pre {
  -ms-line-break: strict;
  line-break: strict;
  word-wrap: break-word;
  word-break: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-family: inherit;
  font-weight: 500;
  color: inherit
}

/* @  Element Reset
* ------------------------------------------------------------ */

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
dt,
dd,
table,
pre,
figure,
img,
blockquote {
  margin: 0;
}

ul,
ol {
  list-style-type: none;
  padding-left: 0;
}

img,
picture {
  max-width: 100%;
  vertical-align: middle;
}

hr {
  border: 0;
  border-bottom: solid 1px $border-color;
  margin-top: $margin-large-pc;
  margin-bottom: $margin-large-pc;

  @include media-tab-max {
    margin-top: $margin-large-tab;
    margin-bottom: $margin-large-tab;
  }

  @include media-sp-max {
    margin-top: $margin-large-sp;
    margin-bottom: $margin-large-sp;
  }

}

table {
  //table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

th {
  text-align: left;
}

/* @  Link Reset
* ------------------------------------------------------------ */

::selection {
  background: $brand-primary-light;
  color: $brand-white;
  text-shadow: none;
}

input::selection,
textarea::selection {
  background: tint($brand-primary-light, 40%);
  //background: shade(blue, 60%);, 40%);
  color: $text-color;
  text-shadow: none;

}

a,
a:link {
  color: inherit;
  text-decoration: none;
  transition: $animation;
}

a:hover,
a:focus,
a:visited {
  transition: $animation;
  //color: $brand-link-hover;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  //color: $brand-link-visited;
}

a {
  -webkit-tap-highlight-color: rgba($brand-primary, .5);
}

a:link {
  -webkit-tap-highlight-color: rgba($brand-primary, .5);
}

button:focus,
a:focus {
  box-shadow: 0 0 3px #3e94cf, 0 0 7px #3e94cf;
}

button:focus {
  outline-width: 0;
}

/* @  Print Reset
* ------------------------------------------------------------ */

@media print {
  header,
  footer,
  .c-breadcrumb,
  .l-secondary-block {
    display: none;
  }
  .l-primary-block {
    width: 93%;
  }
  a[href]:after {
    content: "";
  }

  abbr[title]:after {
    content: "";
  }

}