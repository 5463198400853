@charset "utf-8";


/* 重要なお知らせ

   ========================================================================== */




/*doc
---
title: Important Notices
name: important-notices
category: snippets-other
---

```html_example
<div class="top-components _snippets-important-notices">
  <h2 class="_title"><i class="material-icons">info_outline</i> 重要なお知らせ</h2>
  <ul class="_list">
    <li><a href="#dummy">平成29年9月台風第18号に伴う経済支援（入学検定料、入学料及び授業料の免除）について</a></li>
    <li><a href="#dummy">【日本学生支援機構奨学金】災害救助法適用地域で被災した世帯の学生に対する緊急採用・応急採用について</a></li>
    <li><a href="#dummy">平成29年7月九州北部豪雨に伴う経済支援（入学検定料、入学料及び授業料の免除）について</a></li>
    <li><a href="#dummy">平成28年熊本地震への対応について</a></li>
    <li><a href="#dummy">熊本地震復興事業基金の設置について</a></li>
  </ul>
</div>

```
*/

._snippets-important-notices {
  @include mb-small;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  @include media-sp-max {
    display: block;
  }

  ._title {
    @include fsize(20px, false, 16px);

    font-weight: bold;
    color: $text-color;
    background-color: $brand-secondary;
    margin-right: 24px;
    padding: 17px 24px;
    width: 19%;
    min-width: 170px;

    @include media-sp-max {
      text-align: center;
      margin-right: 0;
      margin-bottom: 14px;
      width: auto;
      padding: 10px;
    }
  }

  ._list {
    li {
      margin-bottom: 3px;
    }

    a {
      color: $text-color;
      @include fsize(16px, false, 14px);
      font-weight: bold;

    }
  }
}
