@charset "utf-8";

.top-components-mb {
  margin-bottom: 50px;
  @include media-tab-max {
    margin-bottom: 30px;
  }
  @include media-sp-max {
    margin-bottom: 30px;
  }
}


.top-title-unit {
  position: relative;
  padding-right: 4em;
  padding-left: 4em;
  margin-bottom: 16px;
  @include media-sp-max {
    margin-bottom: 0;
  }
}


.top-title {
  text-align: center;
  font-weight: bold;
  @include fsize(24px);
  margin-bottom: 10px;
  @include media-sp-max {
    margin-bottom: 5px;
  }
}



.top-title-to-list {
  position: absolute;
  right: 0;
  top: 0;
  a {
    border-radius: $border-radius-pc;
    background-color: #eef0f4;
    padding: .3em .7em;
    display: inline-block;
    color: $text-color;
    @include fsize(14px);
    @include elevation-transition;

    &:hover {
      text-decoration: none;
      @include elevation(4);
    }
    &:active {
      text-decoration: none;
      @include elevation(8);
    }
  }
}



.top-box {
  border: 1px solid $border-color;
  padding: 1.5em;
  @include media-sp-max {
    border: 0;
    padding: 0;
  }
}


.top-box-to-list {
  text-align: center;
  a {
    border-radius: $border-radius-pc;
    background-color: #eef0f4;
    padding: .3em .7em;
    display: inline-block;
    color: $text-color;
    @include fsize(14px);
    @include elevation-transition;

    &:hover {
      text-decoration: none;
      @include elevation(4);
    }
    &:active {
      text-decoration: none;
      @include elevation(8);
    }

  }
}






.top-list-4to1 {
  @include mb-small;
  background: url("#{$img-path}/top/entrance-examination-info_bg.png") center center no-repeat;
  background-size: cover;

  @include media-sp-max {
    margin-right: -$width-gap-sp;
    margin-left: -$width-gap-sp;
    background: url("#{$img-path}/top/entrance-examination-info-sp_bg.png") center center no-repeat;
  }
  &__body {
    padding: 16px 16px 8px;
    @include media-sp-max {
      padding: 24px 16px 16px;
    }

  }
  &__title {
    text-align: center;
    font-weight: bold;
    color: #fff;
    @include fsize(24px);
    margin-bottom: 12px;
    @include media-sp-max {
      margin-bottom: 5px;
    }
  }
  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    @include media-sp-max {
      display: block;
    }

    li {
      flex: 1 1 0;
      margin-right: 12px;
      margin-left: 12px;
      margin-bottom: 16px;
      text-align: center;
      background-color: #eef0f4;
      border-radius: $border-radius-pc;
      white-space: nowrap;

      @media all and (-ms-high-contrast: none) {
        min-width: 17%;
        white-space: normal;
        
      }

      @include media-sp-max {
        width: auto;
        margin-right: 4%;
        margin-left: 4%;
      }

    }

    a {
      @include fsize(14px);
      display: block;
      padding: 19px 6px 16px;
      color: $text-color;

      @include media-sp-max {
        padding: 6px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

}
