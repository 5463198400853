@charset "utf-8";
/* @ color
 * ------------------------------------------------------------ */

@mixin color-base($color-code, $color-name) {

  .is-color#{$color-name} {
    color: $color-code;
  }

  .is-bg#{$color-name} {
    background-color: $color-code;
  }
}

@include color-base ($brand-primary-light, -primary-light);
@include color-base ($brand-primary, -primary);
@include color-base ($brand-primary-dark, -primary-dark);

@include color-base ($brand-secondary-light, -secondary-light);
@include color-base ($brand-secondary, -secondary);
@include color-base ($brand-secondary-dark, -secondary-dark);



@include color-base ($text-color, -text);
@include color-base ($text-color-sub, -text-sub);
@include color-base ($text-color-week, -text-week);

@include color-base ($text-color-dark, -text-dark);
@include color-base ($text-color-dark-sub, -text-dark-sub);
@include color-base ($text-color-dark-week, -text-dark-week);


@include color-base ($brand-white, -white);
@include color-base ($brand-black, -black);
@include color-base ($brand-gray, -gray);

@include color-base ($brand-red, -red);
@include color-base ($brand-pink, -pink);
@include color-base ($brand-orange, -orange);
@include color-base ($brand-light-green, -light-green);
@include color-base ($brand-green, -green);
@include color-base ($brand-blue, -blue);
@include color-base ($brand-purple, -purple);
@include color-base ($brand-brawn, -brawn);
@include color-base ($brand-yellow, -yellow);

@include color-base ($brand-link, -link);
@include color-base ($brand-link-visited, -link-visited);
@include color-base ($brand-link-hover, -link-hover);
@include color-base ($brand-link-dark, -link-dark);
@include color-base ($brand-link-dark-visited, -link-dark-visited);
@include color-base ($brand-link-dark-hover, -link-dark-hover);


@include color-base ($bg-color, -bg-color);
@include color-base ($border-color, -border-color);


/*doc
---
title: color
name: color
category: color
---


<div class="styleguide-table"></div>
 Class        |  Description
 -------------| ----------
 `is-color-success`   |
 `is-color-warning`   |
 `is-color-error`     |
 `is-color-info`      |
 `is-color-bg-success`   |
 `is-color-bg-warning`   |
 `is-color-bg-error`     |
 `is-color-bg-info`      |
 `is-color-red`      |
 `is-color-pink`      |
 `is-color-orange`      |
 `is-color-light-green`      |
 `is-color-green`      |
 `is-color-blue`      |
 `is-color-purple`      |
 `is-color-brawn`      |
 `is-color-yellow`      |

*/


.is-color-success {
  color: $success-color !important;
}

.is-color-warning {
  color: $warning-color !important;
}

.is-color-error {
  color: $error-color !important;
}

.is-color-info {
  color: $info-color !important;
}

.is-color-bg-success {
  background-color: $success-color !important;
}

.is-color-bg-warning {
  background-color: $warning-color !important;
}

.is-color-bg-error {
  background-color: $error-color !important;
}

.is-color-bg-info {
  background-color: $info-color !important;
}
