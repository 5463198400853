@charset "utf-8";

/* @  Header Layout
 * ------------------------------------------------------------ */

.l-header-cols-pc {

  transition: $animation;
  max-width: $width-pc-max;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 0 $width-gap-pc;
  position: relative;

  @include media-tab-max {
    display: none;
  }

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  ._primary {
    flex: 1 1 0;
    @media all and (-ms-high-contrast:none){
      flex: auto;//ie10以上
    }

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    max-width:380px;
  }

  ._icon-list {
    li {
      margin-right: 12px;
      margin-bottom: 12px;
    }

    a {
      display: block;
      width: 160px;
      padding: .5em .8em;
      box-sizing: border-box;
      border-radius: $border-radius-pc;
      color: $text-color;
      font-size: 13px;
      line-height: 1.3;

      @include elevation(2);
      @include elevation-transition;

      &:hover,
      &:focus,
      &.is-hover {
        // background-color: shade($brand-primary, 10%);
        text-decoration: none;
        @include elevation(4);
      }

      &:active {
        //  background-color: shade($brand-primary, 10%);
        text-decoration: none;
        @include elevation(8);
      }


    }

    i {
      font-size: 22px;
      vertical-align: middle;

      color: $text-color-sub;
      margin-right: 3px;
    }

  }

  ._secondary {
   flex: 1 1 0;
    @media all and (-ms-high-contrast:none){
      flex: auto;//ie10以上
    }
    text-align: center;
    margin-bottom: 20px;
    min-width: 120px;
    margin-right: 10px;
    margin-left: 10px;

    a {
      display: inline-block;
    }
  }
  ._tertiary {
    flex: 1 1 0;
    @media all and (-ms-high-contrast:none){
      flex: auto;//ie10以上
    }
    max-width:380px;
    margin-bottom: 20px;
  }

  ._sub-nav1 {
    margin-bottom: 16px;
    ul {
      @include clearfix;
      text-align: right;

    }

    li {
      white-space: nowrap;
      float: left;
      font-size: 12px;
      color: $text-color-sub;
      margin-left: 1.3em;
      &:last-child {
        float: right;
      }

      &:first-child {
        margin-left: 0;
      }
      a {
        display: block;
      }
    }


    ._control-select {
      border: 0;
      vertical-align: middle;
      box-sizing: border-box;
      padding-right: 1em;
      background: transparent url("#{$img-path}/form/form_icon_select_top.svg") right 0 center no-repeat;
      text-align: left;
      text-indent: .01px;
      text-overflow: "";
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 14px;

      &::-ms-expand {
        display: none;
      }

      &:focus {
        background-image: url("#{$img-path}/form/form_icon_select_top.svg");
      }


    }





    button {
      margin-left: .5em;
      width: 3.5em;
      font-weight: bold;
      border-radius: $border-radius-pc;
      border: solid 1px $border-color;
      padding: .4em .5em;
      white-space: nowrap;
      box-sizing: border-box;
      &.is-active {
        background-color: $bg-color;
      }

    }

  }
  ._sub-nav2 {
    margin-bottom: 15px;
    display: table;
    width: 100%;
    text-align: right;

    ul {
      display: table-cell;
      line-height: 1.5;
    }

    li a {
      white-space: nowrap;
      font-size: 13px;
      color: $text-color;
      i {
        position: relative;
        top: 3px;
        font-size: 16px;
        color: $text-color-sub;
      }
    }
    ._btn {

      i {
        font-size: 22px;
        position: relative;
        top: 6px;
        color: $text-color-sub;
      }

      vertical-align: middle;
      display: table-cell;
      padding-left: 16px;
      a {
        display: inline-block;
        font-size: 13px;
        text-decoration: none;
        color: $text-color;
        padding: 5px 13px;
        max-width: 100%;
        box-sizing: border-box;
        transition: all $animation;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        vertical-align: middle;
        background-color: #fff;

        @include elevation(2);
        @include elevation-transition;

        &:hover,
        &:focus,
        &.is-hover {
          text-decoration: none;
          @include elevation(4);
        }

        &:active {
          text-decoration: none;
          @include elevation(8);
        }
      }

    }
  }


  ._search {

    ._body {
      //display: flex;
      //flex-flow: row nowrap;
      //justify-content: flex-start;
      border-radius: $border-radius-pc;
    }

    ._input {
      width: 100%;
      padding: 8px 15px;
      border: 1px solid $border-color;
      border-radius: $border-radius-pc 0 0 $border-radius-pc;
      box-sizing: border-box;
      min-width: 312px;
    }

    ._btn {

      display: block;
      user-select: none;
      font-weight: normal;
      margin: 0 auto;
      //width: 70px;
      width:auto;
      border-radius: 0 $border-radius-pc $border-radius-pc 0;
      font-size: 26px;
      padding-top: 4px;
      border: 0;
      color: $text-color;
      background-color: $brand-secondary;
      -webkit-font-smoothing: antialiased;
      appearance: none;
    }
  }

  #cse {
    height: 40px;
    //google custom検索
    .gsc-search-box {

      .gsc-input-box {
        background: #fff;
        height: auto;
        border: 1px solid $border-color!important;
        border-radius: $border-radius-pc 0 0 $border-radius-pc!important;
        box-sizing: border-box;
        //min-width: 312px;
        box-shadow: 0!important;
      }


      input.gsc-input {
        // _search > _body > _input
        width: 100%;
        padding: 8px 15px!important;
        box-sizing: border-box;
        min-width: 270px;

        //google
        min-height: 35px;
        background-position: 15px center!important;
      }

      .gsc-input {
        padding-right: 0!important;
      }

      .gsc-search-button button.gsc-search-button {
        // _search > _body > _btn
        display: block;
        user-select: none;
        font-weight: normal;
        margin: 0 auto;
        //width: 70px;
        width:auto;
        border-radius: 0 $border-radius-pc $border-radius-pc 0;
        //font-size: 26px;
        padding: 6px 18px 6px 18px;
        border: 0;
        color: $text-color;
        background-image:none;
        background-color: $brand-secondary!important;
        -webkit-font-smoothing: antialiased;
        appearance: none;
        //google
        min-height: 40px;
        font-size: 14px;
      }


    }

  }



}

.l-header-nav-pc {

  @include media-tab-max {
    display: none;
  }

  background-color: $brand-primary;
  transition: $animation;

  nav {
    @include clearfix;
    max-width: $width-pc-max;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $width-gap-pc;

    ul {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }

    li {
      position: relative;
      width: 100%;
      box-sizing: border-box;

      /* IE10以下 */
      width: auto \9;
      text-align: center \9;
      float: left \9;
      /* IE10以下 */


      a {
        white-space: nowrap;
        padding: .84em;
        color: $brand-white;
        font-weight: bold;
        text-decoration: none;
        display: block;
        border-bottom: 3px solid $brand-primary;


        @include media-tab-max {
          font-size: 10px;
        }

      }

      a.stay,
      a.is-active,
      a:hover {
        border-bottom-color:$brand-secondary;

        transition: $animation;
      }

    }
  }

}


.l-header-search-fixed-pc {

  @include media-tab-max {
    display: none;
  }

  top: -600px;
  width:375px;
  margin: auto;
  position: fixed;
  z-index:999;
  left: 0;
  right: -($screen-pc-max / 2) + (-375 / 2) + ( -$screen-gap-pc * 1.6 ) + px;

  @media screen and (max-width: (($screen-pc-max + $screen-gap-pc)  / 16) + em) {
    right: 0;
    margin-right: $width-gap-pc;
  }

  ._search {
    width:375px;
    float: right;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    @include elevation-transition;
    @include elevation(8);
    border-radius: 0 0 $border-radius-pc $border-radius-pc;
    background-color: #fff;
    height: 64px;
    ._logo {
      line-height: 64px;
      width: 82px;
      text-align: center;

      border-right: solid 1px $border-color;
      img {
        width: 50px;
      }
    }

    ._body {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      border-radius: $border-radius-pc;
      padding:16px;
      width:261px;
    }

    ._input {
      width: 100%;
      padding: 8px 15px;
      border: 1px solid $border-color;
      border-radius: $border-radius-pc 0 0 $border-radius-pc;
      box-sizing: border-box;

    }

    ._btn {

      display: block;
      user-select: none;
      font-weight: normal;
      margin: 0 auto;
      //width: 70px;
      width:auto;
      border-radius: 0 $border-radius-pc $border-radius-pc 0;
      font-size: 26px;
      padding-top: 4px;
      border: 0;
      color: $text-color;
      background-color: $brand-secondary;
      -webkit-font-smoothing: antialiased;
      appearance: none;
    }
  }

  //google custom検索
  #cse-fixed {
    width:100%;


    .gsc-search-box {

      .gsc-input-box {
        background: #fff;
        height: auto;
        border: 1px solid $border-color!important;
        border-radius: $border-radius-pc 0 0 $border-radius-pc!important;
        box-sizing: border-box;
        //min-width: 312px;
        box-shadow: 0!important;
      }


      input.gsc-input {
        // _search > _body > _input
        width: 100%;
        padding: 8px 15px!important;
        box-sizing: border-box;
        min-width: 180px;

        //google
        min-height: 35px;
        background-position: 15px center!important;
      }

      .gsc-input {
        padding-right: 0!important;
      }

      .gsc-search-button button.gsc-search-button {
        // _search > _body > _btn
        display: block;
        user-select: none;
        font-weight: normal;
        margin: 0 auto;
        width:auto;
        border-radius: 0 $border-radius-pc $border-radius-pc 0;
        padding: 6px 18px 6px 18px;
        border: 0;
        color: $text-color;
        background-image:none;
        background-color: $brand-secondary!important;
        -webkit-font-smoothing: antialiased;
        appearance: none;
        //google
        min-height: 40px;
        font-size: 14px;
      }


    }


  }
}