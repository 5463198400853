@charset "utf-8";

/* @ horizon
 * ------------------------------------------------------------ */

/*doc
---
title: 画像一覧
name: summary-horizontal-assy
category: summary
---

```html_example
<div class="c-summary-horizontal-assy is-mb-medium">
  <ul class="l-grid-assy-3to2to1">
    <% for (var i = 0; i < 5; i++) { %>
   <li class="l-grid c-card c-card--hover">
        <a href="">
          <div class="c-card__img img-hover"><img src="/assets/img/dummy/pickup.png" alt=""></div>
          <div class="c-card__body">
            <p class="c-card__title">タイトル</p>
            <p class="c-card__text">テキストテキストテキストテキストテキスト</p>
          </div>
        </a>
      </li>
    <% } %>
  </ul>
</div>

<div class="c-pagenavi">
  <a class="c-previouspostslink" rel="prev" href="javascript:void(0)"></a>
  <span class="is-current">1</span>
  <a href="javascript:void(0)">2</a>
  <a href="javascript:void(0)">3</a>
  <a href="javascript:void(0)">4</a>
  <a href="javascript:void(0)">5</a>
  <span class="c-extend">...</span>
  <a class="c-nextpostslink" rel="next" href="javascript:void(0)"></a>
</div>

```
*/

//.c-summary-horizontal-assy {  }

/* @ vertical
 * ------------------------------------------------------------ */

/*doc
---
title: テキスト一覧
name: summary-vertical-assy
category: summary
---


```html_example

<div class="c-summary-vertical-assy is-mb-medium">
   <ul>
     <% for (var i = 0; i < 3; i++) { %>
        <li class="c-media c-media--square media--hover">
          <a href="">
            <div class="c-media__img">
              <img src="/assets/img/dummy/192x128.png" >
              </div>
            <div class="c-media__body">
              <p class="c-media__title">情に棹させば流される智に働けば角が立つ</p>
              <p class="c-media__text is-text-week">テキスト</p>
            </div>
            </a>
        </li>
       <% } %>
    </ul>
 </div>


<div class="c-pagenavi">
  <a class="c-previouspostslink" rel="prev" href="javascript:void(0)"></a>
  <span class="is-current">1</span>
  <a href="javascript:void(0)">2</a>
  <a href="javascript:void(0)">3</a>
  <a href="javascript:void(0)">4</a>
  <a href="javascript:void(0)">5</a>
  <span class="c-extend">...</span>
  <a class="c-nextpostslink" rel="next" href="javascript:void(0)"></a>
</div>

```
*/

.c-summary-vertical-assy {

  li {
    @include mb-medium;
  }

}


