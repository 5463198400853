@charset "utf-8";

/* Alerts
   ========================================================================== */

/*doc
---
title:
name: alert
category: alert
---

<h3 class="styleguide">Success</h3>

```html_example
<div class="e-box-alert e-box-alert--success">
  <div class="e-box-alert__body">
    <p class="e-box-alert__text">情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った</p>
  </div>
</div>
```

<h3 class="styleguide">Warning</h3>
```html_example
<div class="e-box-alert e-box-alert--warning">
  <div class="e-box-alert__body">
    <p class="e-box-alert__text">情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った</p>
  </div>
</div>
```

<h3 class="styleguide">Error</h3>
```html_example
<div class="e-box-alert e-box-alert--error" role="alert">
  <div class="e-box-alert__body">
    <p class="e-box-alert__text">情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った</p>
  </div>
</div>
```


<h3 class="styleguide">Information</h3>
```html_example
<div class="e-box-alert e-box-alert--info">
  <div class="e-box-alert__body">
    <p class="e-box-alert__text">情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った</p>
  </div>
</div>

```




*/

$alert-padding: .6rem 1.5rem .6rem 3.2rem;

.e-box-alert {
  position: relative;
  padding: $alert-padding;
  border-radius: $border-radius-pc;
  border: 1px solid $border-color;
  background-color: $bg-color;
  display: inline-block;
  @include fsize(16px, false, 16px);
  @include mb-small;
  width: 100%;
  box-sizing: border-box;

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1.1rem;
    margin: auto;
    height: 1em;
    @include material-icons;
    vertical-align: middle;
    content: "check";
    white-space: nowrap;
    font-size: 1.5rem;

  }

}

.e-box-alert--success {
  background-color: $success-bg;
  border-color: $success-border;
  color: $success-color;

  &:before {
    color: $success-border;
    content: "check";
  }
}

.e-box-alert--warning {
  background-color: $warning-bg;
  border-color: $warning-border;
  color: $warning-color;

  &:before {
    color: $warning-border;
    content: "warning";
  }
}

.e-box-alert--error {
  background-color: $error-bg;
  border-color: $error-border;
  color: $error-color;

  &:before {
    color: $error-border;
    content: "warning";
  }
}

.e-box-alert--info {
  background-color: $info-bg;
  border-color: $info-border;
  color: $info-color;

  &:before {
    color: $info-border;
    content: "priority_high";
  }
}


