@charset "utf-8";

/* Anchor Menu
   ========================================================================== */

/*doc
---
title:
name: anchor-menu
category: anchor-menu
---

```html_example
<div class="e-anchor-menu">
  <div class="e-anchor-menu__body">
    <p class="e-anchor-menu__title">コンテンツ</p>
    <ul>
      <li><a href="">見だし</a></li>
      <li><a href="">見だし</a></li>
      <li><a href="">見だし</a></li>
      <li><a href="">見だし</a></li>
    </ul>
  </div>
</div>
```

*/

.e-anchor-menu {
  border: 1px solid $border-color;
  @include mb-small;

  &__body {
    padding: 1.6rem 1.6rem 1.2rem;
    position: relative;
  }
  &__title {
    color: $text-color-sub;
    font-weight: bold;
    @include fsize(14px);
    background: #fff;
    position: absolute;
    top: -.5em;
    left: 1.1rem;
    padding-right: .5rem;
    padding-left: .5rem;
    line-height: 1;

  }

  li {
    margin-bottom: .4em;
    &:last-child {
      margin-bottom: 0;
    }

    a {
      text-indent: -.6em;
      padding-left: 1em;
      display: inline-block;

      &:before {
        color: $text-color-sub;
        @include material-icons;
        vertical-align: middle;
        content: "expand_more";
        //margin-right: .6em;
        white-space: nowrap;
        font-size: 1em;

      }
    }

  }
}