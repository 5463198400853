@charset "utf-8";

/* @  Header Layout
 * ------------------------------------------------------------ */

/*doc
---
title: header
name: header
category: header
---


```html_example
<header class="l-header">
</header>
```

*/

//==================================================


.l-header {
  background-color: #fff;
  z-index: 999;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .26);
  margin-bottom: 24px;
  @include media-tab-max {
    margin-bottom: 0;
    height: 54px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

//header fixed
.wrapper {
  @include media-tab-max {
    padding-top: 54px;
  }
}