@charset "utf-8";
/* @ btn
 * ------------------------------------------------------------ */

/*doc
---
title: Label
name: label
category: label
---

```html_example
<span class="e-label">Pickup</span>
<span class="e-label e-label--red">Red</span>
<span class="e-label e-label--brawn">Brawn</span>
<span class="e-label e-label--yellow">Yellow</span>
<span class="e-label e-label--pink">Pink</span>
<span class="e-label e-label--blue">Blue</span>
<span class="e-label e-label--light-green">Light Green</span>
<span class="e-label e-label--green">Green</span>
<span class="e-label e-label--orange">Orange</span>
<span class="e-label e-label--purple">Purple</span>
```
*/

// .rich-editor 内でもつかるように label のcolor とtext-decoration は 「!important」とします

$label-padding-vertical: .25rem;
$label-padding-horizon: .25rem;
$label-border-radius: $border-radius-pc;

.e-label {
  color: $brand-white !important;
  text-decoration: none !important;
  box-sizing: border-box;


  min-width: 3rem;
  display: inline-block;
  padding: $label-padding-vertical $label-padding-horizon;
  border: 0;
  border-radius: $label-border-radius;
  background-color: #aaa;
  font-size: .8rem;
  line-height: 1.2;
  text-align: center;
  //white-space: nowrap;
  vertical-align: text-top;
  user-select: none;
}

h1 > .e-label,
h2 > .e-label,
h3 > .e-label,
h4 > .e-label,
h5 > .e-label,
h6 > .e-label {
  line-height: inherit;
}

@mixin label-color($label-color) {
  background-color: $label-color !important;
}

.e-label--red {
  @include label-color($brand-red);
}

.e-label--pink {
  @include label-color($brand-red);
}

.e-label--brawn {
  @include label-color($brand-brawn);
}

.e-label--blue {
  @include label-color($brand-blue);
}

.e-label--light-green {
  @include label-color($brand-light-green);
}

.e-label--orange {
  @include label-color($brand-orange);
}

.e-label--purple {
  @include label-color($brand-purple);
}

.e-label--green {
  @include label-color($brand-green);
}

