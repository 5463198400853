@charset "utf-8";

/* ==========================================================================
   #headline
   ========================================================================== */

/*doc
---
title: Headline
name: headline
category: headline
---

```html_example

   <ul class="c-headline">
    <li class="c-headline__item">
      <span class="c-headline__date">2017.10.01 </span>
      <span class="c-headline__label">
        <span class="e-label e-label--blue">研究</span>
        <span class="e-label e-label--blue">その他</span>
      </span>
      <p class="c-headline__title"><a href="">ニュースタイトルが入りますニュースタイトルが入ります。ニュースタイトルが入りますニュースタイトルが入ります。</a></p>
    </li>
        <li class="c-headline__item">
      <span class="c-headline__date">2017.10.01 </span>
      <span class="c-headline__label">
        <span class="e-label e-label--blue">その他</span>
      </span>
      <p class="c-headline__title"><a href="">ニュースタイトルが入りますニュースタイトルが入ります。ニュースタイトルが入りますニュースタイトルが入ります。</a></p>
    </li>
   </ul>

```
*/

.c-headline {
  @include mb-small;
  &__item {
    margin-bottom: 1em;
    line-height: 1.4;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    @include media-sp-max {
      display: block;
    }

    a {
      color: $text-color;
    }
  }

  &__date {
    margin: .1em 1em .1em 0;
    @include fsize(12px);
    color: $text-color-sub;
    min-width: 6em;
  }

  &__label {
    margin-right: 1em;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    @include media-sp-max {
      display: inline;
    }

    span {
      margin-right: .3em;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__title {
    @include fsize(14px);
    @include media-sp-max {
      margin-top: .4em;
    }

  }

}

/*doc
---
title: Relation Headline
name: relation-headline
category: headline
---

```html_example

<div class="e-box">
 <div class="e-box__body">
 <div class="e-box__title">関連ニュース</div>
   <ul class="c-relation-headline">
    <li class="c-relation-headline__item">
       <span class="c-relation-headline__date">2017.10.01</span>
       <p class="c-relation-headline__title"><a href="">ニュースタイトルが入りますニュースタイトルが入ります。</a></p>
    </li>
    <li class="c-relation-headline__item">
       <span class="c-relation-headline__date">2017.10.01</span>
       <p class="c-relation-headline__title"><a href="">ニュースタイトルが入りますニュースタイトルが入ります。</a></p>
    </li>
    <li class="c-relation-headline__item">
       <span class="c-relation-headline__date">2017.10.01</span>
       <p class="c-relation-headline__title"><a href="">ニュースタイトルが入りますニュースタイトルが入ります。</a></p>
    </li>
     <li class="c-relation-headline__item">
       <p class="c-relation-headline__title"><a href="">ニュースタイトルが入りますニュースタイトルが入ります。</a></p>
       <span class="c-relation-headline__date">開催日：2017.10.31 - 11.01</span>
    </li>
     <li class="c-relation-headline__item">
       <p class="c-relation-headline__title"><a href="">ニュースタイトルが入りますニュースタイトルが入ります。</a></p>
       <span class="c-relation-headline__date">開催日：2017.10.31 - 11.01</span>
    </li>
     <li class="c-relation-headline__item">
       <p class="c-relation-headline__title"><a href="">ニュースタイトルが入りますニュースタイトルが入ります。</a></p>
       <span class="c-relation-headline__date">開催日：2017.10.31 - 11.01</span>
    </li>
  </ul>
 </div>
</div>

```
*/

.c-relation-headline {
  @include mb-small;
  &__item {
    clear: both;
    margin-bottom: .8em;
    line-height: 1.4;

    a {
      color: $text-color;
    }
  }

  &__date {
    margin: .1em .3em .1em 0;
    @include fsize(12px);
    color: $text-color-sub;
    width: 5rem;
    float: left;
    display: block;

    @include media-sp-max {
      margin-right: 0;
      width: 100%;
      float: none;
    }

    + .c-relation-headline__title {
      padding-left: 5rem;

      @include media-sp-max {
        padding-left: 0;
      }
    }
  }

  &__title {
    @include fsize(14px);
    + .c-relation-headline__date {
      width: 100%;
      float: none;
      margin: 0;
    }
  }

}

/*doc
---
title: search Headline
name: search-headline
category: headline
---

```html_example

   <ul class="c-search-headline">
    <li class="c-search-headline__item">
      <p class="c-search-headline__title"><a href="">ニュースタイトルが入りますニュースタイトルが入ります。ニュースタイトルが入りますニュースタイトルが入ります。</a></p>
      <p class="c-search-headline__url"><a href="">xxx</a></p>
      <p class="c-search-headline__text">ssss<span class="c-search-headline__sub-text">xxx</span></p>
    </li>
   </ul>

```
*/

.c-search-headline {
  @include mb-small;
  &__item {
    @include mb-medium;

  }
  &__title {
    @include fsize(18px);
    margin-bottom: .5em;
    @include media-sp-max {
      margin-bottom: 0;
    }
  }
  &__url {
    @include fsize(14px);
    color: $brand-light-green;
    line-height: 1;
    a {
      color: inherit;
    }
  }
  &__text {
    @include fsize(14px);
    display: inline;
    margin-right: .5em;

  }
  &__sub-text {
    @include fsize(12px);
    color: $text-color-sub;
    display: inline;
  }

}

